import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider, CssBaseline, Box } from '@mui/material';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { lightTheme, darkTheme } from './styles/theme';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Auth from './pages/Auth';
import LedgerList from './pages/LedgerList';
import CreateLedger from './pages/CreateLedger';
import LedgerDetail from './pages/LedgerDetail';
import LedgerAnalysis from './pages/LedgerAnalysis';
import LedgerManage from './pages/LedgerManage';
import RussianRoulette from './pages/RussianRoulette';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Profile from './pages/Profile';
import { AuthProvider } from './contexts/AuthContext';
import { ColorModeProvider, useColorMode } from './contexts/ColorModeContext';
import { Blog } from './pages/Blog/Blog';
import { BlogCreate } from './pages/Blog/BlogCreate';
import { BlogDetail } from './pages/Blog/BlogDetail';
import { BlogEdit } from './pages/Blog/BlogEdit';
import Novel from './pages/Novel/NovelInput';
import NovelReader from './pages/Novel/NovelReader';

// Default SEO component
function DefaultSEO() {
  return (
    <Helmet>
      <title>Shared Tracker - Track and Share Together</title>
      <meta name="description" content="Shared Tracker helps you track and manage shared expenses, create ledgers, and collaborate with others. Join us to make expense tracking easier!" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta property="og:title" content="Shared Tracker - Track and Share Together" />
      <meta property="og:description" content="Track and manage shared expenses easily with Shared Tracker. Create ledgers, collaborate with others, and keep your finances organized." />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Shared Tracker" />
      <meta name="twitter:card" content="summary_large_image" />
      <link rel="canonical" href={window.location.origin} />
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "WebSite",
          "name": "Shared Tracker",
          "description": "Track and manage shared expenses easily with Shared Tracker",
          "url": window.location.origin,
          "potentialAction": {
            "@type": "SearchAction",
            "target": {
              "@type": "EntryPoint",
              "urlTemplate": `${window.location.origin}/blog?search={search_term_string}`
            },
            "query-input": "required name=search_term_string"
          }
        })}
      </script>
    </Helmet>
  );
}

// ProtectedRoute component remains unchanged
const ProtectedRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsAuthenticated(!!token);
    setLoading(false);
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/auth" replace />;
  }

  return children;
};

function AppContent() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { mode } = useColorMode();

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);
  }, []);

  useEffect(() => {
    // Hide loader when React app is ready
    const loader = document.getElementById('loader');
    if (loader) {
      loader.classList.add('fade-out');
      setTimeout(() => {
        loader.style.display = 'none';
      }, 300);
    }
  }, []);

  useEffect(() => {
    // Update theme-color meta tag based on current theme
    const metaThemeColor = document.querySelector("meta[name=theme-color]");
    if (metaThemeColor) {
      metaThemeColor.content = mode === 'dark' ? darkTheme.palette.background.default : lightTheme.palette.background.default;
    }
  }, [mode]);

  const theme = mode === 'dark' ? darkTheme : lightTheme;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <DefaultSEO />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <Header 
            isLoggedIn={isLoggedIn} 
            setIsLoggedIn={setIsLoggedIn} 
            darkMode={mode === 'dark'} 
          />
          <Box component="main" sx={{ 
            flexGrow: 1, 
            mt: { xs: '56px', sm: '64px' }, 
            p: { xs: 1, sm: 2, md: 3 },
            pb: { xs: 'calc(env(safe-area-inset-bottom) + 16px)', sm: 3 }
          }}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/auth" element={<Auth setIsLoggedIn={setIsLoggedIn} />} />
              <Route path="/ledgers" element={
                <ProtectedRoute>
                  <LedgerList />
                </ProtectedRoute>
              } />
              <Route path="/create-ledger" element={
                <ProtectedRoute>
                  <CreateLedger />
                </ProtectedRoute>
              } />
              <Route path="/ledger/:id" element={
                <ProtectedRoute>
                  <LedgerDetail />
                </ProtectedRoute>
              } />
              <Route path="/ledger/:id/analysis" element={
                <ProtectedRoute>
                  <LedgerAnalysis />
                </ProtectedRoute>
              } />
              <Route path="/ledger/:id/manage" element={<LedgerManage />} />
              <Route path="/roulette" element={<RussianRoulette />} />
              <Route path="/profile" element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              } />
              <Route path="/blog" element={<Blog />} />
              <Route path="/blog/:id" element={<BlogDetail />} />
              <Route path="/blog/create" element={
                <ProtectedRoute>
                  <BlogCreate />
                </ProtectedRoute>
              } />
              <Route path="/blog/edit/:id" element={
                <ProtectedRoute>
                  <BlogEdit />
                </ProtectedRoute>
              } />
              {/* Novel Routes */}
              <Route path="/novel" element={<Novel />} />
              <Route path="/novel/read" element={<NovelReader />} />
            </Routes>
          </Box>
          <Footer />
        </Box>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

function App() {
  return (
    <HelmetProvider>
      <ColorModeProvider>
        <AuthProvider>
          <Router>
            <AppContent />
          </Router>
        </AuthProvider>
      </ColorModeProvider>
    </HelmetProvider>
  );
}

export default App;
