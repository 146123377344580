import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { 
  Container, 
  Typography, 
  Button, 
  Box,
  CircularProgress,
  Grid,
  Card,
  CardContent,
  CardActionArea,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Alert,
  useTheme,
  alpha
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { getLedgers, deleteLedger } from '../services/api';

function LedgerList() {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [ledgers, setLedgers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [deletingLedgerId, setDeletingLedgerId] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    fetchLedgers();
  }, []);

  const fetchLedgers = async () => {
    try {
      setLoading(true);
      const data = await getLedgers();
      setLedgers(data);
      
      // Auto-redirect only when coming from home page
      const fromHome = location.state?.from === 'home';
      if (fromHome && data.length === 1) {
        navigate(`/ledger/${data[0]._id}`);
        return;
      }
      
      setError('');
    } catch (err) {
      setError('Failed to fetch trackers. Please try again.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteClick = (ledgerId) => {
    setDeletingLedgerId(ledgerId);
    setDeleteConfirmOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await deleteLedger(deletingLedgerId);
      setSnackbar({ open: true, message: 'Tracker deleted successfully', severity: 'success' });
      fetchLedgers();
    } catch (error) {
      console.error('Failed to delete tracker:', error);
      setSnackbar({ open: true, message: 'Failed to delete tracker', severity: 'error' });
    }
    setDeleteConfirmOpen(false);
    setDeletingLedgerId(null);
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmOpen(false);
    setDeletingLedgerId(null);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  if (loading) {
    return (
      <Box 
        sx={{ 
          minHeight: '60vh', 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center',
          flexDirection: 'column',
          gap: 2
        }}
      >
        <CircularProgress size={40} />
        <Typography variant="body1" color="text.secondary">
          Loading your trackers...
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        minHeight: '100vh',
        pt: 4,
        pb: 6,
        background: theme.palette.mode === 'dark'
          ? `linear-gradient(135deg, ${theme.palette.background.default} 0%, ${alpha(theme.palette.primary.main, 0.1)} 100%)`
          : `linear-gradient(135deg, ${theme.palette.background.default} 0%, ${alpha(theme.palette.primary.light, 0.1)} 100%)`,
      }}
    >
      <Container maxWidth="md">
        {/* Header Section */}
        <Box sx={{ mb: 6, textAlign: 'center' }}>
          <Typography 
            variant="h3" 
            component="h1" 
            sx={{ 
              fontWeight: 700,
              mb: 2,
              background: theme.palette.mode === 'dark'
                ? `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.light} 90%)`
                : `linear-gradient(45deg, ${theme.palette.primary.dark} 30%, ${theme.palette.primary.main} 90%)`,
              backgroundClip: 'text',
              textFillColor: 'transparent',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            My Shared Trackers
          </Typography>
          <Button
            variant="contained"
            size="large"
            color="primary"
            startIcon={<AddIcon />}
            component={RouterLink}
            to="/create-ledger"
            sx={{
              borderRadius: 2,
              py: 1.5,
              px: 4,
              textTransform: 'none',
              fontSize: '1.1rem',
              background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.light} 90%)`,
              '&:hover': {
                background: `linear-gradient(45deg, ${theme.palette.primary.dark} 30%, ${theme.palette.primary.main} 90%)`,
              }
            }}
          >
            Create New Tracker
          </Button>
        </Box>

        {error && (
          <Alert severity="error" sx={{ mb: 4 }}>
            {error}
          </Alert>
        )}

        {ledgers.length > 0 ? (
          <Grid container spacing={3}>
            {ledgers.map((ledger, index) => (
              <Grid item xs={12} sm={6} md={4} key={ledger._id}>
                <Card 
                  elevation={theme.palette.mode === 'dark' ? 2 : 0}
                  sx={{ 
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: 3,
                    position: 'relative',
                    overflow: 'visible',
                    bgcolor: theme.palette.background.paper,
                    transition: theme.transitions.create(['transform', 'box-shadow'], {
                      duration: theme.transitions.duration.shorter,
                    }),
                    '&:hover': {
                      transform: 'translateY(-4px)',
                      boxShadow: theme.shadows[10],
                    },
                  }}
                >
                  <Box
                    sx={{
                      position: 'absolute',
                      top: -20,
                      left: 20,
                      width: 40,
                      height: 40,
                      borderRadius: '50%',
                      backgroundColor: theme.palette.primary.main,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      boxShadow: theme.shadows[4],
                    }}
                  >
                    <AccountBalanceWalletIcon sx={{ color: '#fff' }} />
                  </Box>
                  <CardActionArea 
                    component={RouterLink} 
                    to={`/ledger/${ledger._id}`}
                    sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', height: '100%' }}
                  >
                    <CardContent sx={{ flexGrow: 1, width: '100%', pt: 4 }}>
                      <Typography 
                        variant="h6" 
                        component="h2" 
                        gutterBottom
                        sx={{
                          fontWeight: 600,
                          color: theme.palette.text.primary,
                          mb: 1,
                        }}
                      >
                        {ledger.name}
                      </Typography>
                      <Typography 
                        variant="body2" 
                        color="text.secondary"
                        sx={{ mb: 2 }}
                      >
                        Click to view details and manage expenses
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  {ledger.owner === ledger.currentUser && (
                    <Box sx={{ p: 2, pt: 0 }}>
                      <Button
                        startIcon={<DeleteIcon sx={{ fontSize: 18 }} />}
                        color="error"
                        size="small"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleDeleteClick(ledger._id);
                        }}
                        sx={{ 
                          borderRadius: 2,
                          textTransform: 'none',
                          fontSize: '0.875rem',
                          float: 'right',
                        }}
                      >
                        Delete
                      </Button>
                    </Box>
                  )}
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Box 
            sx={{ 
              textAlign: 'center', 
              mt: 8,
              p: 4,
              borderRadius: 3,
              bgcolor: alpha(theme.palette.primary.main, 0.05),
            }}
          >
            <Typography 
              variant="h6" 
              sx={{ 
                mb: 2,
                color: theme.palette.text.primary,
                fontWeight: 500
              }}
            >
              No Trackers Yet
            </Typography>
            <Typography 
              variant="body1" 
              color="text.secondary"
              sx={{ mb: 3 }}
            >
              Create your first tracker to start managing expenses with friends!
            </Typography>
          </Box>
        )}

        <Dialog
          open={deleteConfirmOpen}
          onClose={handleDeleteCancel}
          PaperProps={{
            sx: {
              borderRadius: 3,
              p: 2
            }
          }}
        >
          <DialogTitle sx={{ fontWeight: 600 }}>
            Delete Tracker?
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this tracker? This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ px: 3, pb: 3 }}>
            <Button 
              onClick={handleDeleteCancel}
              sx={{ 
                textTransform: 'none',
                borderRadius: 2
              }}
            >
              Cancel
            </Button>
            <Button 
              onClick={handleDeleteConfirm} 
              variant="contained" 
              color="error"
              sx={{ 
                textTransform: 'none',
                borderRadius: 2
              }}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar 
          open={snackbar.open} 
          autoHideDuration={6000} 
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert 
            onClose={handleSnackbarClose} 
            severity={snackbar.severity} 
            variant="filled"
            sx={{ width: '100%', borderRadius: 2 }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Container>
    </Box>
  );
}

export default LedgerList;