import React from 'react';
import { Box, Container, Grid, Typography, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        py: 4,
        px: 2,
        mt: 'auto',
        backgroundColor: (theme) => theme.palette.grey[900],
        color: 'white',
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Shared Tracker
            </Typography>
            <Typography variant="body2" color="grey.400">
              Simple and efficient way to track shared expenses and income.
            </Typography>
          </Grid>
          
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Quick Links
            </Typography>
            <Link component={RouterLink} to="/" color="grey.400" sx={{ display: 'block', mb: 1 }}>
              Home
            </Link>
            <Link component={RouterLink} to="/roulette" color="grey.400" sx={{ display: 'block', mb: 1 }}>
              Russian Roulette
            </Link>
          </Grid>
        </Grid>
        
        <Typography 
          variant="body2" 
          color="grey.400" 
          align="center"
          sx={{ mt: 4, borderTop: '1px solid rgba(255,255,255,0.1)', pt: 2 }}
        >
          © {new Date().getFullYear()} Shared Tracker. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
}

export default Footer;