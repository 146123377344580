import React, { createContext, useContext, useState, useEffect } from 'react';
import { getUserProfile } from '../services/api';

const AuthContext = createContext(null);

export function AuthProvider({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchUserInfo = async () => {
    try {
      const userData = await getUserProfile();
      setUser(userData);
      return userData;
    } catch (error) {
      console.error('Failed to fetch user info:', error);
      logout();
      return null;
    }
  };

  useEffect(() => {
    const initializeAuth = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const userData = await fetchUserInfo();
          if (userData) {
            setIsLoggedIn(true);
          }
        } catch (error) {
          console.error('Auth initialization failed:', error);
          logout();
        }
      }
      setLoading(false);
    };

    initializeAuth();
  }, []);

  const login = async (token, userData) => {
    localStorage.setItem('token', token);
    setIsLoggedIn(true);
    
    if (userData) {
      setUser(userData);
    } else {
      // If userData is not provided, fetch it from the server
      await fetchUserInfo();
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    setUser(null);
  };

  const refreshUserInfo = async () => {
    if (isLoggedIn) {
      return await fetchUserInfo();
    }
    return null;
  };

  const value = {
    isLoggedIn,
    user,
    loading,
    login,
    logout,
    refreshUserInfo
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
} 