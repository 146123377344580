import React, { useCallback } from 'react';
import {
  Box,
  IconButton,
  Divider,
  Tooltip,
  TextField,
  Paper,
  CircularProgress,
  Typography
} from '@mui/material';
import {
  FormatBold,
  FormatItalic,
  FormatListBulleted,
  FormatListNumbered,
  Code,
  Link,
  Image as ImageIcon,
  FormatQuote,
  Title,
  Looks3,
  Looks4,
  Looks5,
  Looks6
} from '@mui/icons-material';
import { uploadImage } from '../../../services/api';
import { v4 as uuidv4 } from 'uuid';
import { MarkdownComponents } from './MarkdownComponents';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';

const TOOLBAR_ITEMS = [
  { icon: <Title />, tooltip: 'Heading 1 (# )', format: '# ' },
  { icon: <Looks3 />, tooltip: 'Heading 2 (## )', format: '## ' },
  { icon: <Looks4 />, tooltip: 'Heading 3 (### )', format: '### ' },
  { icon: <Looks5 />, tooltip: 'Heading 4 (#### )', format: '#### ' },
  { icon: <Looks6 />, tooltip: 'Heading 5 (##### )', format: '##### ' },
  { type: 'divider' },
  { icon: <FormatBold />, tooltip: 'Bold (**text**)', format: '**', wrap: true },
  { icon: <FormatItalic />, tooltip: 'Italic (*text*)', format: '*', wrap: true },
  { type: 'divider' },
  { icon: <FormatListBulleted />, tooltip: 'Bullet List (- )', format: '- ' },
  { icon: <FormatListNumbered />, tooltip: 'Numbered List (1. )', format: '1. ' },
  { type: 'divider' },
  { icon: <Code />, tooltip: 'Code Block (```)', format: '```\n', wrap: true, multiline: true },
  { icon: <FormatQuote />, tooltip: 'Quote (> )', format: '> ' },
  { type: 'divider' },
  { icon: <Link />, tooltip: 'Link [text](url)', format: '[', wrap: true, suffix: ']()' },
  { icon: <ImageIcon />, tooltip: 'Upload Image', type: 'image' }
];

export function MarkdownEditor({ value, onChange, disabled, showSplitView = false }) {
  const textFieldRef = React.useRef(null);
  const textareaRef = React.useRef(null);
  const [uploading, setUploading] = React.useState(false);
  const [uploadError, setUploadError] = React.useState(null);
  const fileInputRef = React.useRef(null);
  const [uploadingImages, setUploadingImages] = React.useState(new Map());

  const insertText = useCallback((text, options = {}) => {
    const textField = textFieldRef.current;
    if (!textField) return;

    const inputElement = textField.querySelector('textarea');
    if (!inputElement) return;

    const start = inputElement.selectionStart || 0;
    const end = inputElement.selectionEnd || 0;
    const currentValue = value || '';
    const selectedText = currentValue.substring(start, end);
    const format = options.format || text || '';

    let newText;
    let cursorPos;

    if (options.wrap && selectedText) {
      const suffix = options.suffix || format;
      newText = currentValue.substring(0, start) +
        format + selectedText + suffix +
        currentValue.substring(end);
      cursorPos = start + format.length + selectedText.length + suffix.length;
    } else if (options.multiline) {
      const prefix = currentValue.substring(0, start);
      const postfix = currentValue.substring(end);
      
      const needsNewlineBefore = prefix.length > 0 && !prefix.endsWith('\n');
      const needsNewlineAfter = postfix.length > 0 && !postfix.startsWith('\n');
      
      newText = prefix +
        (needsNewlineBefore ? '\n' : '') +
        format +
        selectedText +
        (selectedText ? '\n' : '') +
        format +
        (needsNewlineAfter ? '\n' : '') +
        postfix;
      
      cursorPos = start + format.length + (needsNewlineBefore ? 1 : 0);
    } else {
      const lineStart = currentValue.lastIndexOf('\n', start - 1) + 1;
      newText = currentValue.substring(0, lineStart) +
        format + currentValue.substring(lineStart);
      cursorPos = lineStart + format.length;
    }

    onChange({ target: { name: 'content', value: newText } });

    setTimeout(() => {
      const updatedInput = textFieldRef.current?.querySelector('textarea');
      if (updatedInput) {
        updatedInput.focus();
        updatedInput.setSelectionRange(cursorPos, cursorPos);
      }
    }, 0);
  }, [value, onChange]);

  const handleImageUpload = async (event) => {
    console.log('Image upload triggered');
    const file = event.target.files?.[0];
    if (!file) {
      console.log('No file selected');
      return;
    }

    console.log('Selected file:', file);

    try {
      setUploadError(null);
      setUploading(true);
      
      // Get current cursor position using the direct textarea reference
      if (!textareaRef.current) {
        console.log('Textarea not found');
        return;
      }

      const cursorPosition = textareaRef.current.selectionStart;
      const currentValue = value || '';
      
      // Generate a temporary ID and URL
      const tempId = uuidv4();
      const tempUrl = URL.createObjectURL(file);
      console.log('Created temp URL:', tempUrl);
      
      // Create temporary markdown with proper newlines
      const loadingMarkdown = `![Uploading ${file.name}...](${tempUrl})`;
      
      // Insert at cursor position with proper spacing
      const beforeCursor = currentValue.substring(0, cursorPosition);
      const afterCursor = currentValue.substring(cursorPosition);
      
      // Add newlines only if needed
      const needsNewlineBefore = beforeCursor.length > 0 && !beforeCursor.endsWith('\n');
      const needsNewlineAfter = afterCursor.length > 0 && !afterCursor.startsWith('\n');
      
      const newText = beforeCursor +
        (needsNewlineBefore ? '\n' : '') +
        loadingMarkdown +
        (needsNewlineAfter ? '\n' : '') +
        afterCursor;
      
      // Update content with loading placeholder
      onChange({ target: { name: 'content', value: newText } });
      
      // Track this upload
      setUploadingImages(prev => new Map(prev).set(tempId, {
        tempUrl,
        loadingMarkdown,
        file,
        position: cursorPosition
      }));
      
      console.log('Starting upload to server');
      const imageUrl = await uploadImage(file);
      console.log('Received image URL:', imageUrl);
      
      if (!imageUrl) {
        throw new Error('No image URL received from server');
      }
      
      // Replace the temporary markdown with the final one
      const finalMarkdown = `![${file.name}](${imageUrl})`;
      const updatedText = newText.replace(loadingMarkdown, finalMarkdown);
      
      // Update content with final URL
      onChange({ target: { name: 'content', value: updatedText } });
      console.log('Updated content with final URL');
      
      // Restore cursor position after the inserted image
      setTimeout(() => {
        if (textareaRef.current) {
          const newPosition = cursorPosition + 
            (needsNewlineBefore ? 1 : 0) + 
            finalMarkdown.length +
            (needsNewlineAfter ? 1 : 0);
          textareaRef.current.focus();
          textareaRef.current.setSelectionRange(newPosition, newPosition);
        }
      }, 0);
      
      // Cleanup
      URL.revokeObjectURL(tempUrl);
      setUploadingImages(prev => {
        const next = new Map(prev);
        next.delete(tempId);
        return next;
      });
      
    } catch (error) {
      console.error('Failed to upload image:', error);
      setUploadError(error.message || 'Failed to upload image');
      
      // Remove the loading placeholder on error
      const currentValue = value || '';
      const loadingImage = Array.from(uploadingImages.values())
        .find(img => img.file === file);
      
      if (loadingImage) {
        const updatedText = currentValue.replace(
          loadingImage.loadingMarkdown,
          '*Failed to upload image*'
        );
        onChange({ target: { name: 'content', value: updatedText } });
      }
      
      // Cleanup on error
      Array.from(uploadingImages.entries()).forEach(([id, data]) => {
        if (data.file === file) {
          URL.revokeObjectURL(data.tempUrl);
          setUploadingImages(prev => {
            const next = new Map(prev);
            next.delete(id);
            return next;
          });
        }
      });
    } finally {
      setUploading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  // Custom components for ReactMarkdown to fix DOM nesting issues
  const components = {
    p: ({ node, children, ...props }) => {
      // Check if the paragraph contains only an image
      const hasOnlyImage = node.children.length === 1 && node.children[0].type === 'image';
      
      // If it's just an image, don't wrap in a paragraph
      if (hasOnlyImage) {
        return children;
      }
      
      return (
        <Typography component="p" variant="body1" {...props}>
          {children}
        </Typography>
      );
    },
    img: ({ node, ...props }) => (
      <Box 
        component="span" 
        sx={{ 
          display: 'block',
          my: 2,
          '& img': {
            maxWidth: '100%',
            height: 'auto'
          }
        }}
      >
        <img {...props} style={{ maxWidth: '100%' }} alt={props.alt || ''} />
      </Box>
    )
  };

  return (
    <Box sx={{ 
      width: '100%',
      maxWidth: '100%',
      '& .MuiPaper-root': {
        maxWidth: '100%'
      }
    }}>
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        width: '100%',
        maxWidth: '100%',
        '& > *': {
          maxWidth: '100%'
        }
      }}>
        <Paper 
          variant="outlined"
          sx={{ 
            mb: 2,
            p: 1,
            display: 'flex',
            flexWrap: 'wrap',
            gap: 0.5,
            alignItems: 'center',
            maxWidth: '100%',
            overflow: 'hidden'
          }}
        >
          {TOOLBAR_ITEMS.map((item, index) => {
            if (item.type === 'divider') {
              return <Divider orientation="vertical" flexItem key={`divider-${index}`} />;
            }

            if (item.type === 'image') {
              return (
                <Box key="image-upload" sx={{ position: 'relative' }}>
                  <input
                    ref={fileInputRef}
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleImageUpload}
                    onClick={(e) => {
                      // Reset the input value to allow selecting the same file again
                      e.target.value = '';
                    }}
                  />
                  <Tooltip title={uploadError || item.tooltip}>
                    <span>
                      <IconButton
                        size="small"
                        onClick={() => {
                          console.log('Image button clicked');
                          fileInputRef.current?.click();
                        }}
                        disabled={disabled || uploading}
                        color={uploadError ? 'error' : 'default'}
                      >
                        {uploading ? <CircularProgress size={24} /> : item.icon}
                      </IconButton>
                    </span>
                  </Tooltip>
                </Box>
              );
            }

            return (
              <Tooltip key={item.tooltip} title={item.tooltip}>
                <IconButton
                  size="small"
                  onClick={() => insertText(item.format, {
                    wrap: item.wrap,
                    suffix: item.suffix,
                    multiline: item.multiline
                  })}
                  disabled={disabled}
                >
                  {item.icon}
                </IconButton>
              </Tooltip>
            );
          })}
        </Paper>

        <Box sx={{ 
          display: 'flex', 
          flexDirection: showSplitView ? 'row' : 'column', 
          gap: 2, 
          height: '500px',
          width: '100%',
          maxWidth: '100%',
          overflow: 'hidden'
        }}>
          <Box sx={{ 
            flex: showSplitView ? '0 1 50%' : 1,
            minWidth: 0,
            maxWidth: showSplitView ? '50%' : '100%'
          }}>
            <TextField
              inputRef={(el) => {
                textFieldRef.current = el;
                textareaRef.current = el;
              }}
              multiline
              fullWidth
              value={value}
              onChange={(e) => onChange({ target: { name: 'content', value: e.target.value } })}
              disabled={disabled}
              variant="outlined"
              placeholder="Write your content here (Markdown supported)"
              sx={{
                height: '100%',
                maxWidth: '100%',
                '& .MuiInputBase-root': {
                  height: '100%',
                  fontFamily: 'monospace',
                  fontSize: '0.9rem',
                  maxWidth: '100%'
                },
                '& .MuiInputBase-input': {
                  height: '100% !important',
                  overflowY: 'auto !important',
                  maxWidth: '100%'
                }
              }}
            />
          </Box>
          {showSplitView && (
            <Box sx={{ 
              flex: '0 1 50%',
              minWidth: 0,
              maxWidth: '50%',
              height: '100%',
              overflow: 'auto'
            }}>
              <Paper 
                variant="outlined" 
                sx={{ 
                  p: 2,
                  height: '100%',
                  overflow: 'auto'
                }}
              >
                <ReactMarkdown
                  children={value || ''}
                  remarkPlugins={[remarkGfm]}
                  rehypePlugins={[rehypeRaw, rehypeSanitize]}
                  components={components}
                />
              </Paper>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
} 