import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CloseIcon from '@mui/icons-material/Close';
import NumericKeypad from '../NumericKeypad';

function TransactionEditModal({ isOpen, onClose, editingRecord, setEditingRecord, onSave, categories }) {
  const [amountInput, setAmountInput] = useState('');
  const [amountError, setAmountError] = useState('');
  const [isAmountFocused, setIsAmountFocused] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const amountInputRef = useRef(null);

  useEffect(() => {
    if (editingRecord?.amount) {
      setAmountInput(editingRecord.amount.toString());
    } else {
      setAmountInput('');
    }
    setAmountError('');
  }, [editingRecord]);

  const handleAmountChange = (e) => {
    const value = e.target.value.replace(/[^\d+\-\.\s]/g, '');
    setAmountInput(value);
    setAmountError('');
  };

  const handleAmountBlur = (e) => {
    if (e.relatedTarget?.closest('.numeric-keypad')) return;
    validateAmount(amountInput);
    setIsAmountFocused(false);
  };

  const validateAmount = (input) => {
    try {
      const trimmedInput = input.replace(/\s/g, '');
      if (!trimmedInput) {
        throw new Error('Amount is required');
      }
      if (!/^[\d+\-\.]+$/.test(trimmedInput)) {
        throw new Error('Invalid characters in input');
      }
      const result = eval(trimmedInput);
      if (isNaN(result) || result <= 0) {
        throw new Error('Amount must be positive');
      }
      setEditingRecord({ ...editingRecord, amount: Number(result.toFixed(2)) });
      setAmountInput(result.toFixed(2));
      setAmountError('');
      return true;
    } catch (error) {
      setAmountError(error.message);
      return false;
    }
  };

  const handleKeypadInput = (value) => {
    if (value === 'backspace') {
      setAmountInput(prev => prev.slice(0, -1));
    } else {
      setAmountInput(prev => prev + value);
    }
    amountInputRef.current?.focus();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateAmount(amountInput)) {
      onSave(event);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          ...(isMobile && {
            margin: 2,
            maxHeight: '80vh',
            borderRadius: 2,
            position: 'relative'
          })
        }
      }}
      sx={{
        '& .MuiDialog-container': {
          alignItems: 'center',
        }
      }}
    >
      <DialogTitle 
        sx={{ 
          m: 0, 
          p: 2, 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'space-between',
          backgroundColor: 'background.paper',
          borderBottom: '1px solid',
          borderColor: 'divider',
          position: 'relative'
        }}
      >
        <Typography variant="h6" component="div" sx={{ pr: 4 }}>
          {editingRecord?.id ? 'Edit Transaction' : 'New Transaction'}
        </Typography>
        <IconButton 
          onClick={onClose} 
          size="small"
          sx={{
            position: 'absolute',
            right: 8,
            top: '50%',
            transform: 'translateY(-50%)',
            ...(isMobile && {
              padding: '8px',
              right: 4,
            })
          }}
        >
          <CloseIcon fontSize={isMobile ? 'medium' : 'small'} />
        </IconButton>
      </DialogTitle>

      <DialogContent 
        sx={{
          p: 2,
          ...(isMobile && {
            pb: isAmountFocused ? '240px' : 2,
            overflowY: 'auto',
          })
        }}
      >
        <form id="transaction-form" onSubmit={handleSubmit}>
          <Stack spacing={2.5} sx={{ mt: 1 }}>
            <TextField
              label="Description"
              value={editingRecord?.description || ''}
              onChange={(e) => setEditingRecord({ ...editingRecord, description: e.target.value })}
              fullWidth
              variant="outlined"
              InputProps={{
                sx: { borderRadius: 2 }
              }}
            />

            <TextField
              label="Amount"
              value={amountInput}
              onChange={handleAmountChange}
              onBlur={handleAmountBlur}
              onFocus={() => setIsAmountFocused(true)}
              error={!!amountError}
              helperText={amountError || "Use +/- for calculations"}
              required
              fullWidth
              variant="outlined"
              InputProps={{
                readOnly: isMobile,
                sx: { borderRadius: 2 }
              }}
              inputRef={amountInputRef}
              inputProps={{
                inputMode: isMobile ? 'none' : 'numeric',
              }}
            />

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <FormControl fullWidth variant="outlined">
                <InputLabel 
                  sx={{ 
                    backgroundColor: 'background.paper',
                    px: 1,
                  }}
                >
                  Currency
                </InputLabel>
                <Select
                  value={editingRecord?.currency || ''}
                  onChange={(e) => setEditingRecord({ ...editingRecord, currency: e.target.value })}
                  required
                  sx={{ borderRadius: 2 }}
                  label="Currency"
                >
                  <MenuItem key="USD" value="USD">USD</MenuItem>
                  <MenuItem key="CNY" value="CNY">CNY</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth variant="outlined">
                <InputLabel 
                  sx={{ 
                    backgroundColor: 'background.paper',
                    px: 1,
                  }}
                >
                  Category
                </InputLabel>
                <Select
                  value={editingRecord?.categoryModel?._id || ''}
                  onChange={(e) => {
                    const selectedCategory = categories.find(cat => cat._id === e.target.value);
                    setEditingRecord({ ...editingRecord, categoryModel: selectedCategory })
                  }}
                  required
                  sx={{ borderRadius: 2 }}
                  label="Category"
                >
                  {categories.filter(cat => cat.type === editingRecord?.type).map((category) => (
                    <MenuItem key={category._id} value={category._id}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              
            </Stack>

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Date"
                value={editingRecord?.date || null}
                onChange={(newValue) => setEditingRecord({...editingRecord, date: newValue})}
                renderInput={(params) => (
                  <TextField 
                    {...params} 
                    fullWidth 
                    required 
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      sx: { borderRadius: 2 }
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Stack>
        </form>
      </DialogContent>

      <DialogActions 
        sx={{ 
          p: 2,
          backgroundColor: 'background.paper',
          borderTop: '1px solid',
          borderColor: 'divider',
          position: 'relative',
          bottom: 'auto'
        }}
      >
        <Button 
          onClick={onClose} 
          color="inherit"
          sx={{ borderRadius: 2 }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          form="transaction-form"
          variant="contained"
          disabled={!!amountError}
          sx={{ borderRadius: 2 }}
        >
          Save
        </Button>
      </DialogActions>

      {isMobile && isAmountFocused && (
        <Box 
          className="numeric-keypad" 
          sx={{ 
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: 'background.paper',
            borderTop: 1,
            borderColor: 'divider',
            zIndex: theme.zIndex.modal + 1,
            boxShadow: 3,
            maxHeight: '240px'
          }}
        >
          <NumericKeypad
            onInput={handleKeypadInput}
            onClear={() => setAmountInput('')}
            onSubmit={() => {
              validateAmount(amountInput);
              setIsAmountFocused(false);
            }}
          />
        </Box>
      )}
    </Dialog>
  );
}

export default TransactionEditModal;
