import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Paper,
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
  TextField,
  Chip,
  useTheme,
  useMediaQuery,
  Collapse,
  IconButton,
  LinearProgress,
  Button,
  Tooltip as MuiTooltip,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { getLedgerTransactions, getExchangeRate } from '../services/api';
import SummarySection from '../components/LedgerDetail/SummarySection';
import {
  startOfMonth,
  endOfMonth,
  subDays,
  subMonths,
  addMonths,
  format,
  startOfWeek,
  startOfYear,
  differenceInDays,
  isFuture,
  isAfter,
  isSameMonth
} from 'date-fns';
import { alpha } from '@mui/material/styles';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import IncomeExpenseTrend from '../components/IncomeExpenseTrend';
import moment from 'moment';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

// 更新预设按钮样式
const presetButtonStyles = {
  chip: {
    margin: '4px',
    transition: 'all 0.3s',
    fontSize: '0.6rem',
    height: '28px',
    minWidth: '30px',
    justifyContent: 'center', // 确保文本居中
  },
  activeChip: {
    backgroundColor: (theme) => theme.palette.primary.main,
    color: (theme) => theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: (theme) => theme.palette.primary.dark,
    },
  },
};

function LedgerAnalysis() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [analysisType, setAnalysisType] = useState('expense');
  const [exchangeRate, setExchangeRate] = useState(7.0);
  const [startDate, setStartDate] = useState(startOfMonth(new Date()));
  const [endDate, setEndDate] = useState(new Date());
  const [activePreset, setActivePreset] = useState('');
  const [expandedCategory, setExpandedCategory] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const datePresets = [
    { value: 'today', label: 'Today' },
    { value: 'yesterday', label: 'Yesterday' },
    { value: 'thisWeek', label: 'This Week' },
    { value: 'last7Days', label: 'Last 7 Days' },
    { value: 'last30Days', label: 'Last 30 Days' },
    { value: 'last3Months', label: 'Last 3 Months' },
    { value: 'thisYear', label: 'This Year' },
  ];

  const fetchTransactions = useCallback(async () => {
    try {
      setLoading(true);
      // 修改 API 调用，传入 startDate 和 endDate
      const data = await getLedgerTransactions(id, startDate, endDate);
      setTransactions(data);
      setError('');
    } catch (err) {
      setError('Failed to fetch transactions. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [id, startDate, endDate]);

  useEffect(() => {
    fetchTransactions();
    fetchExchangeRate();
  }, [fetchTransactions]);

  const fetchExchangeRate = async () => {
    try {
      const rate = await getExchangeRate('USD', 'CNY');
      setExchangeRate(rate);
    } catch (error) {
      console.error('Failed to fetch exchange rate:', error);
    }
  };

  const convertToUSD = (amount, currency) => {
    if (currency === 'USD') return amount;
    if (currency === 'CNY') return amount / exchangeRate;
    return amount;
  };

  const calculateTotal = (type) => {
    return transactions.reduce((sum, t) => {
      if (t.type === type) {
        return sum + convertToUSD(t.amount, t.currency);
      }
      return sum;
    }, 0).toFixed(2);
  };

  const calculateBalance = () => {
    return transactions.reduce((sum, t) => {
      const amountUSD = convertToUSD(t.amount, t.currency);
      return t.type === 'income' ? sum + amountUSD : sum - amountUSD;
    }, 0).toFixed(2);
  };

  const calculateDailyAverage = (type) => {
    const total = parseFloat(calculateTotal(type));
    const days = differenceInDays(endDate, startDate) + 1; // +1 to include both start and end dates
    return (total / days).toFixed(2);
  };

  const getCategoryData = () => {
    const categoryData = transactions
      .filter(t => t.type === analysisType)
      .reduce((acc, t) => {
        const categoryName = t.categoryModel ? t.categoryModel.name : 'Uncategorized';
        if (!acc[categoryName]) {
          acc[categoryName] = { total: 0, transactions: [] };
        }
        const amountUSD = convertToUSD(t.amount, t.currency);
        acc[categoryName].total += amountUSD;
        acc[categoryName].transactions.push(t);
        return acc;
      }, {});

    const total = Object.values(categoryData).reduce((sum, { total }) => sum + total, 0);

    return Object.entries(categoryData).map(([category, data]) => ({
      category,
      amount: data.total,
      percentage: ((data.total / total) * 100).toFixed(2),
      transactions: data.transactions
    })).sort((a, b) => b.amount - a.amount);
  };

  const categoryData = getCategoryData();

  const handleDatePreset = (preset) => {
    const today = new Date();
    setActivePreset(preset);
    let newStartDate, newEndDate;
    switch (preset) {
      case 'today':
        newStartDate = today;
        newEndDate = today;
        break;
      case 'yesterday':
        newStartDate = subDays(today, 1);
        newEndDate = subDays(today, 1);
        break;
      case 'thisWeek':
        newStartDate = startOfWeek(today);
        newEndDate = today;
        break;
      case 'thisMonth':
        newStartDate = startOfMonth(today);
        newEndDate = today;
        break;
      case 'last7Days':
        newStartDate = subDays(today, 6);
        newEndDate = today;
        break;
      case 'last14Days':
        newStartDate = subDays(today, 13);
        newEndDate = today;
        break;
      case 'last30Days':
        newStartDate = subDays(today, 29);
        newEndDate = today;
        break;
      case 'last3Months':
        newStartDate = subMonths(today, 3);
        newEndDate = today;
        break;
      case 'thisYear':
        newStartDate = startOfYear(today);
        newEndDate = today;
        break;
      case 'allTime':
        newStartDate = new Date(0); // 设置为 Unix 时间戳开始
        newEndDate = today;
        break;
      default:
        return;
    }
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    // 不需要在这里调用 fetchTransactions，因为 useEffect 会理
  };

  const handleCategoryClick = (category) => {
    setExpandedCategory(expandedCategory === category ? null : category);
  };

  const prepareChartData = (transactions, startDate, endDate) => {
    const dailyData = {};
    const currentDate = moment(startDate).startOf('day');
    const endMoment = moment(endDate).endOf('day');

    while (currentDate.isSameOrBefore(endMoment)) {
      const dateString = currentDate.format('YYYY-MM-DD');
      dailyData[dateString] = { income: 0, expense: 0 };
      currentDate.add(1, 'days');
    }

    transactions.forEach(t => {
      const dateString = moment(t.date).format('YYYY-MM-DD');
      
      if (dailyData[dateString]) {
        const amountUSD = convertToUSD(t.amount, t.currency);
        dailyData[dateString][t.type] += amountUSD;
      }
    });

    const labels = Object.keys(dailyData);
    const incomeData = labels.map(date => Number(dailyData[date].income.toFixed(2)));
    const expenseData = labels.map(date => Number(dailyData[date].expense.toFixed(2)));

    return {
      labels,
      datasets: [
        {
          label: 'Income',
          data: incomeData,
          borderColor: 'rgb(75, 192, 192)',
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          tension: 0.4,
          fill: true,
          pointRadius: 0,
        },
        {
          label: 'Expense',
          data: expenseData,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          tension: 0.4,
          fill: true,
          pointRadius: 0,
        },
      ],
    };
  };

  const chartData = prepareChartData(transactions, startDate, endDate);

  const handlePreviousMonth = () => {
    const previousMonth = subMonths(endDate, 1);
    setStartDate(startOfMonth(previousMonth));
    setEndDate(endOfMonth(previousMonth));
    setActivePreset('');
  };

  const handleNextMonth = () => {
    const nextMonth = addMonths(endDate, 1);
    const today = new Date();
    
    if (isFuture(startOfMonth(nextMonth))) {
      // 如果下个月是未来，不做任何操作
      return;
    }
    
    setStartDate(startOfMonth(nextMonth));
    
    if (isSameMonth(nextMonth, today)) {
      // 如果下个月是当前月，结束日期设置为今天
      setEndDate(today);
    } else {
      setEndDate(endOfMonth(nextMonth));
    }
    
    setActivePreset('');
  };

  if (loading) {
    return (
      <Container maxWidth="sm" sx={{ mt: 4, textAlign: 'center' }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="sm" sx={{ mt: 4, textAlign: 'center' }}>
        <Typography color="error">{error}</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate(`/ledger/${id}`)}
        sx={{ mb: 2 }}
      >
        Back to Ledger
      </Button>
      <Typography variant="h4" component="h1" gutterBottom>
        Analysis
      </Typography>
      <Box sx={{ 
        position: 'sticky', 
        top: '56px', // 根据你的应用布局调整这个值
        zIndex: 1000, 
        backgroundColor: 'background.default',
        mb: 1, // 增加底部边距
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // 添加更强的阴影
      }}>
        <Paper elevation={2} sx={{ p: 2 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'row', 
              gap: 1, 
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <MuiTooltip title="Switch to previous month">
                <IconButton onClick={handlePreviousMonth} size="small">
                  <ArrowBackIosNewIcon fontSize="small" />
                </IconButton>
              </MuiTooltip>
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={(newValue) => {
                  if (!isFuture(newValue)) {
                    setStartDate(newValue);
                    setActivePreset('');
                  }
                }}
                maxDate={new Date()} // 限制最大日期为今天
                renderInput={(params) => <TextField {...params} size="small" />}
              />
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={(newValue) => {
                  if (!isFuture(newValue) && !isAfter(newValue, new Date())) {
                    setEndDate(newValue);
                    setActivePreset('');
                  }
                }}
                maxDate={new Date()} // 限制最大日期为今天
                renderInput={(params) => <TextField {...params} size="small" />}
              />
              <MuiTooltip title="Switch to next month">
                <span>
                  <IconButton 
                    onClick={handleNextMonth} 
                    size="small" 
                    disabled={isSameMonth(endDate, new Date()) || isFuture(addMonths(startOfMonth(endDate), 1))}
                  >
                    <ArrowForwardIosIcon fontSize="small" />
                  </IconButton>
                </span>
              </MuiTooltip>
            </Box>
          </LocalizationProvider>
        </Paper>
      </Box>
      
      <Paper elevation={2} sx={{ p: 2, mb: 1, mt: 1 }}>
        <Typography variant="body2" align="center" sx={{ fontWeight: 'medium', color: 'text.secondary', fontSize: '0.8rem' }}>
          {format(startDate, 'MMM dd, yyyy')} - {format(endDate, 'MMM dd, yyyy')}
        </Typography>
        <Box sx={{ 
          display: 'flex', 
          flexWrap: 'wrap', 
          gap: 0.5, 
          justifyContent: 'center',
          maxWidth: '100%',
          margin: '0 auto',
          mt: 2,
        }}>
          {datePresets.map((preset) => (
            <Chip
              key={preset.value}
              label={preset.label}
              onClick={() => handleDatePreset(preset.value)}
              size="small"
              sx={{
                ...presetButtonStyles.chip,
                ...(activePreset === preset.value ? presetButtonStyles.activeChip : {}),
              }}
            />
          ))}
        </Box>
      </Paper>
      
      <Paper elevation={2} sx={{ p: 3, mb: 1 }}>
        <SummarySection 
          calculateTotal={calculateTotal}
          calculateBalance={calculateBalance}
          calculateDailyAverage={calculateDailyAverage}
          exchangeRate={exchangeRate}
          transactions={transactions}
          convertToUSD={convertToUSD}
          startDate={startDate}
          endDate={endDate}
        />
      </Paper>
      <Paper elevation={1} sx={{ p: 1, mb: 1 }}>
        <IncomeExpenseTrend chartData={chartData} />
      </Paper>
      
      <Paper elevation={2} sx={{ p: 3, mb: 3 }}>
        <ToggleButtonGroup
          value={analysisType}
          exclusive
          onChange={(e, newValue) => setAnalysisType(newValue)}
          aria-label="analysis type"
          sx={{ mb: 2 }}
        >
          <ToggleButton value="expense" aria-label="expense">
            Expense
          </ToggleButton>
          <ToggleButton value="income" aria-label="income">
            Income
          </ToggleButton>
        </ToggleButtonGroup>
        <Typography variant="h6" gutterBottom>
          {analysisType === 'expense' ? 'Expense' : 'Income'} Distribution by Category
        </Typography>
        <List sx={{ bgcolor: 'background.paper' }}>
          {categoryData.map((item, index) => (
            <React.Fragment key={item.category}>
              <ListItem 
                button 
                onClick={() => handleCategoryClick(item.category)}
                sx={{
                  bgcolor: index % 2 === 0 ? 'background.default' : 'background.paper',
                  '&:hover': {
                    bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1),
                  },
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                  <Typography variant="subtitle1">
                    {item.category}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    ${item.amount.toFixed(2)} ({item.percentage}%)
                  </Typography>
                </Box>
                <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress 
                      variant="determinate" 
                      value={parseFloat(item.percentage)} 
                      sx={{
                        height: 10,
                        borderRadius: 5,
                        backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
                        '& .MuiLinearProgress-bar': {
                          borderRadius: 5,
                          backgroundColor: (theme) => theme.palette.primary.main,
                        },
                      }}
                    />
                  </Box>
                  <IconButton edge="end" aria-label="expand" size="small">
                    {expandedCategory === item.category ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </Box>
              </ListItem>
              <Collapse in={expandedCategory === item.category} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {item.transactions.map((transaction, transIndex) => (
                    <ListItem 
                      key={transIndex} 
                      sx={{ 
                        pl: 4,
                        bgcolor: transIndex % 2 === 0 
                          ? (theme) => alpha(theme.palette.primary.main, 0.05)
                          : (theme) => alpha(theme.palette.primary.main, 0.02),
                      }}
                    >
                      <ListItemText
                        primary={
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="body2">
                              {transaction.amount.toFixed(2)} {transaction.currency} - {transaction.description}
                            </Typography>
                            <Typography variant="caption" color="text.secondary">
                              By: {transaction.createdBy ? transaction.createdBy.username : 'Unknown'}
                            </Typography>
                          </Box>
                        }
                        secondary={
                          <Typography variant="caption" color="text.secondary">
                            {format(new Date(transaction.date), 'MMM dd, yyyy')}
                          </Typography>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
              {index < categoryData.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </Paper>
    </Container>
  );
}

export default LedgerAnalysis;