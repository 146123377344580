import React from 'react';
import {
  Box,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Chip,
  Button,
  Stack,
  Tooltip,
  Card,
  CardContent,
  CardHeader,
  Tab,
  Tabs,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CategoryIcon from '@mui/icons-material/Category';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

function ManageSection({
  ledger,
  categories = [],
  onAddCategory,
  onEditCategory,
  onDeleteCategory,
  setIsCollaboratorModalOpen,
}) {
  const [selectedTab, setSelectedTab] = React.useState('expense');

  const expenseCategories = categories.filter(category => category.type === 'expense');
  const incomeCategories = categories.filter(category => category.type === 'income');

  const renderCategoryList = (categories) => (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 2 }}>
      {categories.map((category) => (
        <Box
          key={category._id}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            p: 1,
            borderRadius: 1,
            border: '1px solid',
            borderColor: 'divider',
            '&:hover': {
              bgcolor: 'action.hover',
            },
          }}
        >
          <Chip
            label={category.name}
            color={category.type === 'expense' ? 'error' : 'success'}
            variant="outlined"
            size="medium"
            icon={category.type === 'expense' ? <ShoppingCartIcon /> : <MonetizationOnIcon />}
            sx={{
              '& .MuiChip-icon': {
                color: 'inherit'
              }
            }}
          />
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Tooltip title="Edit Category">
              <IconButton
                size="small"
                onClick={() => onEditCategory(category)}
                color="primary"
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Category">
              <IconButton
                size="small"
                onClick={() => onDeleteCategory(category._id)}
                color="error"
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      ))}
      {categories.length === 0 && (
        <Typography variant="body2" color="text.secondary" sx={{ p: 2, width: '100%', textAlign: 'center' }}>
          No categories found. Click the "Add Category" button to create one.
        </Typography>
      )}
    </Box>
  );

  return (
    <Paper elevation={3} sx={{ p: 3 }}>
      {/* Collaborators Section */}
      <Card elevation={0} sx={{ mb: 3, border: 1, borderColor: 'divider' }}>
        <CardHeader
          title={
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="h6">Collaborators</Typography>
            </Stack>
          }
          action={
            ledger && ledger.owner === ledger.currentUser && (
              <Button
                startIcon={<PersonAddIcon />}
                onClick={() => setIsCollaboratorModalOpen(true)}
                variant="outlined"
                size="small"
              >
                Add Collaborator
              </Button>
            )
          }
        />
        <CardContent>
          <List>
            {ledger && [ledger.owner, ...ledger.collaborators].map((user, index) => (
              <ListItem
                key={user}
                sx={{
                  borderRadius: 1,
                  bgcolor: index === 0 ? 'primary.50' : 'transparent',
                  mb: 1
                }}
              >
                <ListItemText
                  primary={user}
                  secondary={index === 0 ? 'Owner' : 'Collaborator'}
                />
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>

      {/* Categories Section */}
      <Card elevation={0} sx={{ border: 1, borderColor: 'divider' }}>
        <CardHeader
          title={
            <Stack direction="row" alignItems="center" spacing={1}>
              <CategoryIcon />
              <Typography variant="h6">Categories</Typography>
            </Stack>
          }
          action={
            <Button
              startIcon={<AddIcon />}
              onClick={onAddCategory}
              variant="contained"
              size="small"
              color="primary"
            >
              Add Category
            </Button>
          }
        />
        <CardContent>
          <Tabs
            value={selectedTab}
            onChange={(e, newValue) => setSelectedTab(newValue)}
            sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}
          >
            <Tab
              label="Expense"
              value="expense"
              icon={<ShoppingCartIcon />}
              iconPosition="start"
            />
            <Tab
              label="Income"
              value="income"
              icon={<MonetizationOnIcon />}
              iconPosition="start"
            />
          </Tabs>
          
          {selectedTab === 'expense' ? (
            renderCategoryList(expenseCategories)
          ) : (
            renderCategoryList(incomeCategories)
          )}
        </CardContent>
      </Card>
    </Paper>
  );
}

export default ManageSection;