import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  TextField,
  Button,
  Typography,
  Paper,
  CircularProgress,
  Alert,
  useTheme,
  useMediaQuery,
  Grid,
  Card,
  CardActionArea,
  CardContent,
  Skeleton,
  Tooltip,
} from '@mui/material';
import { BookOnline, Language, Schedule } from '@mui/icons-material';
import { getSupportedDomains } from '../../services/api';

const formatLastUpdated = (date) => {
  if (!date) return '';
  const now = new Date();
  const updated = new Date(date);
  const diffInHours = (now - updated) / (1000 * 60 * 60);
  
  if (diffInHours < 1) {
    const minutes = Math.floor((now - updated) / (1000 * 60));
    return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
  } else if (diffInHours < 24) {
    const hours = Math.floor(diffInHours);
    return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
  } else {
    const days = Math.floor(diffInHours / 24);
    if (days < 30) {
      return `${days} day${days !== 1 ? 's' : ''} ago`;
    } else {
      return updated.toLocaleDateString();
    }
  }
};

export default function NovelInput() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [domains, setDomains] = useState([]);  
  const [loadingDomains, setLoadingDomains] = useState(true);
  const [domainsError, setDomainsError] = useState('');

  useEffect(() => {
    const fetchDomains = async () => {
      try {
        setDomainsError('');
        const data = await getSupportedDomains();
        // Sort domains by lastUpdated (most recent first)
        const sortedDomains = (data.domains || []).sort((a, b) => {
          if (!a.lastUpdated) return 1;
          if (!b.lastUpdated) return -1;
          return new Date(b.lastUpdated) - new Date(a.lastUpdated);
        });
        setDomains(sortedDomains);
      } catch (error) {
        console.error('Error fetching supported domains:', error);
        setDomainsError('Failed to load supported websites');
        setDomains([]);
      } finally {
        setLoadingDomains(false);
      }
    };

    fetchDomains();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!url) {
      setError('Please enter a URL');
      return;
    }
    setLoading(true);
    const encodedUrl = encodeURIComponent(url);
    navigate(`/novel/read?url=${encodedUrl}`);
  };

  const handleDomainClick = (domain) => {
    if (domain.lastUrl) {
      navigate(`/novel/read?url=${encodeURIComponent(domain.lastUrl)}`);
    } else {
      window.open(`https://${domain.domain}`, '_blank');
    }
  };

  return (
    <Box 
      sx={{ 
        minHeight: '100vh',
        bgcolor: 'background.default',
        pt: { xs: 2, sm: 4 },
        pb: { xs: 4, sm: 6 },
      }}
    >
      <Container maxWidth="md">
        <Paper
          component="form"
          onSubmit={handleSubmit}
          sx={{
            p: { xs: 3, sm: 6 },
            borderRadius: 3,
            textAlign: 'center',
            boxShadow: theme.shadows[8],
          }}
        >
          <BookOnline 
            sx={{ 
              fontSize: { xs: 48, sm: 64 },
              color: theme.palette.primary.main,
              mb: 2,
            }}
          />
          
          <Typography 
            variant={isMobile ? "h5" : "h4"}
            component="h1" 
            gutterBottom 
            fontWeight="bold"
            sx={{ mb: 3 }}
          >
            Novel Reader
          </Typography>

          <Typography 
            variant="body1" 
            color="text.secondary"
            sx={{ mb: 4 }}
          >
            Enter the URL of the novel chapter you want to read
          </Typography>

          <Box 
            sx={{ 
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              gap: 2,
              maxWidth: 'md',
              mx: 'auto',
            }}
          >
            <TextField
              fullWidth
              label="Novel URL"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              error={!!error}
              helperText={error}
              sx={{ flex: 1 }}
              placeholder="https://example.com/novel/chapter-1"
            />
            <Button
              variant="contained"
              type="submit"
              disabled={loading}
              sx={{
                height: { xs: 48, sm: 56 },
                px: { xs: 3, sm: 6 },
                minWidth: { xs: '100%', sm: 120 },
                background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.light} 90%)`,
                '&:hover': {
                  background: `linear-gradient(45deg, ${theme.palette.primary.dark} 30%, ${theme.palette.primary.main} 90%)`,
                },
              }}
            >
              {loading ? <CircularProgress size={24} /> : 'Start Reading'}
            </Button>
          </Box>

          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
        </Paper>

        <Box sx={{ mt: 4 }}>
          <Typography 
            variant="h6" 
            gutterBottom 
            sx={{ 
              display: 'flex', 
              alignItems: 'center',
              gap: 1,
              mb: 3,
              color: 'primary.main',
              fontWeight: 'bold'
            }}
          >
            <Language />
            Supported Novel Sources
          </Typography>
          
          <Grid container spacing={2}>
            {loadingDomains ? (
              // Loading skeletons
              [...Array(6)].map((_, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Skeleton 
                    variant="rounded" 
                    height={120} 
                    animation="wave"
                    sx={{ borderRadius: 2 }}
                  />
                </Grid>
              ))
            ) : domainsError ? (
              // Domain error message
              <Grid item xs={12}>
                <Alert severity="error">
                  {domainsError}
                </Alert>
              </Grid>
            ) : domains.length > 0 ? (
              // Domain cards
              domains.map((domain) => (
                <Grid item xs={12} sm={6} md={4} key={domain.domain}>
                  <Card 
                    sx={{ 
                      height: '100%',
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        transform: 'translateY(-4px)',
                        boxShadow: (theme) => theme.shadows[8],
                        bgcolor: 'action.hover'
                      },
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    <CardActionArea 
                      onClick={() => handleDomainClick(domain)}
                      sx={{ 
                        height: '100%', 
                        p: 2.5,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start'
                      }}
                    >
                      <CardContent sx={{ width: '100%', p: 0 }}>
                        <Box sx={{ 
                          display: 'flex', 
                          alignItems: 'center', 
                          gap: 1,
                          mb: 1.5
                        }}>
                          <Language sx={{ 
                            color: domain.lastUrl ? 'success.main' : 'text.secondary',
                            fontSize: 20 
                          }} />
                          <Typography 
                            variant="h6" 
                            component="div"
                            sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              fontWeight: 'medium',
                              fontSize: '1rem'
                            }}
                          >
                            {domain.domain}
                          </Typography>
                        </Box>
                        {domain.lastUrl && (
                          <Typography 
                            variant="body2" 
                            color="text.secondary"
                            sx={{ 
                              mt: 0.5,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              maxWidth: '100%'
                            }}
                          >
                            Last read: {decodeURIComponent(domain.lastUrl)}
                          </Typography>
                        )}
                        <Box sx={{ 
                          mt: 1.5,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          width: '100%'
                        }}>
                          <Typography 
                            variant="button" 
                            color={domain.lastUrl ? "success.main" : "primary.main"}
                            sx={{ 
                              fontSize: '0.75rem',
                              fontWeight: 'medium'
                            }}
                          >
                            {domain.lastUrl ? 'Continue Reading →' : 'Visit Website →'}
                          </Typography>
                          {domain.lastUpdated && (
                            <Tooltip title={new Date(domain.lastUpdated).toLocaleString()}>
                              <Box sx={{ 
                                display: 'flex', 
                                alignItems: 'center', 
                                gap: 0.5,
                                color: 'text.secondary',
                                fontSize: '0.75rem'
                              }}>
                                <Schedule sx={{ fontSize: '0.875rem' }} />
                                {formatLastUpdated(domain.lastUpdated)}
                              </Box>
                            </Tooltip>
                          )}
                        </Box>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))
            ) : (
              // No domains message
              <Grid item xs={12}>
                <Alert severity="info">
                  No supported websites available yet.
                </Alert>
              </Grid>
            )}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}
