import { createTheme } from '@mui/material/styles';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#5b6abf', // 柔和的蓝紫色，给人可靠和专业的感觉
      light: '#8e99f3',
      dark: '#26418f',
    },
    secondary: {
      main: '#ffa726', // 温暖的橙色，代表家庭和舒适
      light: '#ffd95b',
      dark: '#c77800',
    },
    background: {
      default: '#f8f9fa',
      paper: '#ffffff',
    },
    text: {
      primary: '#37474f',
      secondary: '#78909c',
    },
    error: {
      main: '#ef5350', // 柔和的红色，用于警告或负数
    },
    success: {
      main: '#66bb6a', // 柔和的绿色，用于正数或达成目标
    },
  },
  typography: {
    fontFamily: '"Nunito", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontWeight: 600,
    },
    button: {
      textTransform: 'none',
    },
  },
});

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#8c9eff', // Brighter blue-purple for better visibility
      light: '#c0cfff',
      dark: '#5870cb',
    },
    secondary: {
      main: '#ffb74d', // Kept the orange but slightly adjusted
      light: '#ffe97d',
      dark: '#c88719',
    },
    background: {
      default: '#1a1a2e', // Darker, more sophisticated background
      paper: '#282840', // Slightly lighter than default for contrast
    },
    text: {
      primary: '#ffffff', // Pure white for primary text - better readability
      secondary: '#b0bec5', // Kept the same for secondary text
    },
    error: {
      main: '#ff6b6b', // More vibrant red for better visibility
      light: '#ff9999',
      dark: '#cc5555',
    },
    success: {
      main: '#4caf50', // More vibrant green for better visibility
      light: '#80e27e',
      dark: '#087f23',
    },
    divider: 'rgba(255, 255, 255, 0.12)', // Better defined divider color
  },
  typography: {
    fontFamily: '"Nunito", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontWeight: 600,
    },
    h2: {
      fontWeight: 600,
    },
    h3: {
      fontWeight: 600,
    },
    button: {
      textTransform: 'none',
      fontWeight: 500,
    },
    body1: {
      lineHeight: 1.6,
    },
    body2: {
      lineHeight: 1.5,
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none', // Removes default paper texture
        },
      },
    },
  },
});

export { lightTheme, darkTheme };
