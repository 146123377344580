import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

function AddCategoryModal({ isOpen, onClose, onConfirm }) {
  const [newCategory, setNewCategory] = useState({
    name: '',
    type: 'expense'
  });

  const handleSubmit = () => {
    onConfirm(newCategory);
    setNewCategory({ name: '', type: 'expense' }); // Reset form
  };

  const handleClose = () => {
    setNewCategory({ name: '', type: 'expense' }); // Reset form
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>Add New Category</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          margin="normal"
          label="Category Name"
          value={newCategory.name}
          onChange={(e) => setNewCategory({ ...newCategory, name: e.target.value })}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>Type</InputLabel>
          <Select
            value={newCategory.type}
            label="Type"
            onChange={(e) => setNewCategory({ ...newCategory, type: e.target.value })}
          >
            <MenuItem value="income">Income</MenuItem>
            <MenuItem value="expense">Expense</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button 
          onClick={handleSubmit} 
          color="primary" 
          variant="contained"
          disabled={!newCategory.name.trim()}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddCategoryModal;