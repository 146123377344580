import React from 'react';
import { Grid, Button, Paper, useTheme } from '@mui/material';
import BackspaceIcon from '@mui/icons-material/Backspace';

function NumericKeypad({ onInput, onClear, onSubmit }) {
  const theme = useTheme();
  const buttons = [
    '7', '8', '9', 'backspace',
    '4', '5', '6', '+',
    '1', '2', '3', '-',
    '0', '.'
  ];

  const getButtonStyle = (btn) => ({
    fontSize: '1.5rem',
    fontWeight: 'bold',
    height: '60px',
    borderRadius: '12px',
    transition: 'background-color 0.1s',
    ...(btn === 'backspace' && { 
      color: theme.palette.common.white,
      backgroundColor: theme.palette.error.main,
      '&:hover': {
        backgroundColor: theme.palette.error.dark,
      },
      '&:active': {
        backgroundColor: theme.palette.error.main,
      },
    }),
    ...(btn === '+' || btn === '-' && { 
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
      '&:active': {
        backgroundColor: theme.palette.primary.main,
      },
    }),
    ...(btn !== 'backspace' && btn !== '+' && btn !== '-' && {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
      '&:hover': {
        backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      },
      '&:active': {
        backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
      },
    }),
  });

  // 添加一个新的函数来处理按钮点击和震动
  const handleButtonClick = (btn) => {
    // 检查设备是否支持震动
    if ('vibrate' in navigator) {
      // 对于不同类型的按钮使用不同的震动模式
      if (btn === 'backspace') {
        navigator.vibrate(50); // 较长的震动
      } else if (btn === '+' || btn === '-' || btn === 'Submit') {
        navigator.vibrate([30, 30]); // 两次短震动
      } else {
        navigator.vibrate(20); // 短震动
      }
    }
    
    // 调用原有的 onInput 函数
    onInput(btn);
  };

  return (
    <Paper 
      elevation={3} 
      className="numeric-keypad"
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        padding: 2,
        backgroundColor: theme.palette.background.paper,
        borderTopLeftRadius: 24,
        borderTopRightRadius: 24,
        zIndex: theme.zIndex.modal + 1, // 确保键盘在最上层
      }}
    >
      <Grid container spacing={1.5}>
        {buttons.map((btn, index) => (
          <Grid item xs={3} key={index}>
            <Button
              fullWidth
              variant="contained"
              onClick={() => handleButtonClick(btn)}
              sx={getButtonStyle(btn)}
            >
              {btn === 'backspace' ? <BackspaceIcon fontSize="large" /> : btn}
            </Button>
          </Grid>
        ))}
        <Grid item xs={6}>
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              onSubmit();
            }}
            sx={{ 
              height: '60px', 
              fontSize: '1.5rem', 
              fontWeight: 'bold',
              backgroundColor: theme.palette.success.main,
              color: theme.palette.common.white,
              borderRadius: '12px',
              transition: 'background-color 0.1s',
              '&:hover': {
                backgroundColor: theme.palette.success.dark,
              },
              '&:active': {
                backgroundColor: theme.palette.success.main,
              },
            }}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default NumericKeypad;
