import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Typography, 
  Grid, 
  Card, 
  CardContent, 
  CardMedia, 
  CardActionArea, 
  Box, 
  CircularProgress,
  Pagination,
  Stack,
  Button
} from '@mui/material';
import { getArticles } from '../../services/api';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { useAuth } from '../../contexts/AuthContext';

// Function to generate random pastel color
const getRandomPastelColor = () => {
  // Generate lighter colors by mixing with white
  const mix = (color) => Math.floor((color + 255) / 2);
  const r = mix(Math.random() * 255);
  const g = mix(Math.random() * 255);
  const b = mix(Math.random() * 255);
  return `rgb(${r}, ${g}, ${b})`;
};

export function Blog() {
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
    itemsPerPage: 12
  });

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        setLoading(true);
        const response = await getArticles(page, 12);
        const { articles, pagination: paginationData } = response.data;
        
        // Add random colors for posts without images
        const articlesWithColors = (Array.isArray(articles) ? articles : []).map(article => ({
          ...article,
          defaultColor: getRandomPastelColor()
        }));
        
        setPosts(articlesWithColors);
        setPagination(paginationData);
      } catch (err) {
        setError('Failed to fetch blog posts');
        console.error('Error fetching posts:', err);
        setPosts([]);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, [page]); // Re-fetch when page changes

  const handlePageChange = (event, value) => {
    setPage(value);
    window.scrollTo(0, 0); // Scroll to top when page changes
  };

  const handlePostClick = (postId) => {
    navigate(`/blog/${postId}`);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          mb: 4 
        }}>
          <Typography variant="h4" component="h1">
            Latest Blog Posts
          </Typography>
          {isLoggedIn && (
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => navigate('/blog/create')}
            >
              Create Post
            </Button>
          )}
        </Box>
        {error ? (
          <Typography color="error" align="center">
            {error}
          </Typography>
        ) : posts.length === 0 ? (
          <Typography align="center" color="text.secondary">
            No blog posts available at the moment.
          </Typography>
        ) : (
          <>
            <Grid container spacing={4} sx={{ mb: 4 }}>
              {posts.map((post) => (
                <Grid item xs={12} sm={6} md={4} key={post._id || post.id}>
                  <Card elevation={3} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <CardActionArea 
                      onClick={() => handlePostClick(post._id || post.id)}
                      sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}
                    >
                      {post.coverImage ? (
                        <Box sx={{ position: 'relative' }}>
                          <CardMedia
                            component="img"
                            height="200"
                            image={post.coverImage}
                            alt={post.title}
                          />
                          <Box
                            sx={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              background: 'linear-gradient(to bottom, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.8) 100%)',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'flex-end',
                              p: 2
                            }}
                          >
                            <Typography 
                              variant="h6" 
                              component="h2" 
                              sx={{ 
                                color: 'white',
                                textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
                                mb: 1
                              }}
                            >
                              {post.title}
                            </Typography>
                            <Box sx={{ display: 'flex', gap: 2, color: 'rgba(255,255,255,0.9)' }}>
                              <Typography 
                                variant="caption"
                                sx={{ textShadow: '1px 1px 2px rgba(0,0,0,0.5)' }}
                              >
                                By {post.author?.nickname || post.author?.username || 'Unknown'}
                              </Typography>
                              <Typography 
                                variant="caption"
                                sx={{ textShadow: '1px 1px 2px rgba(0,0,0,0.5)' }}
                              >
                                {post.pv || 0} views
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      ) : (
                        <Box sx={{ position: 'relative' }}>
                          <Box
                            sx={{
                              height: 200,
                              backgroundColor: post.defaultColor,
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'flex-end',
                              p: 2
                            }}
                          >
                            <Typography 
                              variant="h6" 
                              component="h2" 
                              sx={{ mb: 1 }}
                            >
                              {post.title}
                            </Typography>
                            <Box sx={{ display: 'flex', gap: 2, color: 'text.secondary' }}>
                              <Typography variant="caption">
                                By {post.author?.nickname || post.author?.username || 'Unknown'}
                              </Typography>
                              <Typography variant="caption">
                                {post.pv || 0} views
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      )}
                      <CardContent sx={{ flexGrow: 1 }}>
                        <Typography 
                          variant="body2" 
                          color="text.secondary"
                          sx={{
                            display: '-webkit-box',
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                          }}
                        >
                          {post.summary}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>
            
            {/* Pagination Controls */}
            <Stack spacing={2} alignItems="center" sx={{ mt: 4 }}>
              <Pagination 
                count={pagination.totalPages}
                page={pagination.currentPage}
                onChange={handlePageChange}
                color="primary"
                size="large"
                showFirstButton
                showLastButton
              />
              <Typography variant="body2" color="text.secondary">
                Showing {posts.length} of {pagination.totalItems} posts
              </Typography>
            </Stack>
          </>
        )}
      </Box>
    </Container>
  );
}