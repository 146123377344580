import { Typography, Box } from '@mui/material';

export const MarkdownComponents = {
  h1: (props) => (
    <Typography variant="h4" component="h1" gutterBottom sx={{ mt: 4, mb: 2 }} {...props} />
  ),
  h2: (props) => (
    <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 3, mb: 2 }} {...props} />
  ),
  h3: (props) => (
    <Typography variant="h6" component="h3" gutterBottom sx={{ mt: 2, mb: 1 }} {...props} />
  ),
  p: (props) => (
    <Box sx={{ mb: 2 }}>
      <Typography variant="body1" sx={{ lineHeight: 1.8 }} {...props} />
    </Box>
  ),
  a: (props) => (
    <a 
      {...props} 
      style={{ 
        color: '#1976d2',
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline'
        }
      }} 
    />
  ),
  img: (props) => (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        my: 3,
        maxHeight: '350px',
        overflow: 'hidden'
      }}
    >
      <Box
        component="img"
        sx={{
          maxWidth: '100%',
          maxHeight: '350px',
          width: 'auto',
          height: 'auto',
          objectFit: 'contain',
          borderRadius: 1,
          boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
          backgroundColor: 'background.paper'
        }}
        {...props}
        loading="lazy"
        onError={(e) => {
          console.error('Image load error:', e.target.src);
          e.target.style.display = 'none';
        }}
      />
    </Box>
  ),
  blockquote: (props) => (
    <Box
      component="blockquote"
      sx={{
        borderLeft: 4,
        borderColor: 'primary.main',
        pl: 2,
        py: 1,
        my: 2,
        bgcolor: 'action.hover',
        borderRadius: 1
      }}
      {...props}
    />
  ),
  code: (props) => (
    <Box
      component="code"
      sx={{
        bgcolor: 'action.hover',
        p: 0.5,
        borderRadius: 0.5,
        fontFamily: 'Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace',
        fontSize: '0.875em'
      }}
      {...props}
    />
  ),
  pre: (props) => (
    <Box
      component="pre"
      sx={{
        bgcolor: 'action.hover',
        p: 2,
        borderRadius: 1,
        overflow: 'auto',
        '& code': {
          bgcolor: 'transparent',
          p: 0
        }
      }}
      {...props}
    />
  ),
}; 