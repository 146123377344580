import React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';

function SimpleSummarySection({ calculateTotal, calculateDailyAverage }) {
  return (
    <Box>
      <Typography variant="h6" sx={{ mb: 2 }}>Transactions Summary (USD)</Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
        <Paper elevation={1} sx={{ flex: 1, p: 2, minWidth: '120px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <TrendingUpIcon color="success" sx={{ mr: 1 }} />
            <Typography variant="subtitle1">Income</Typography>
          </Box>
          <Typography variant="h6" color="success.main">
            ${calculateTotal('income')}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            Daily Avg: ${calculateDailyAverage('income')}
          </Typography>
        </Paper>
        <Paper elevation={1} sx={{ flex: 1, p: 2, minWidth: '120px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <TrendingDownIcon color="error" sx={{ mr: 1 }} />
            <Typography variant="subtitle1">Expenses</Typography>
          </Box>
          <Typography variant="h6" color="error.main">
            ${calculateTotal('expense')}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            Daily Avg: ${calculateDailyAverage('expense')}
          </Typography>
        </Paper>
      </Box>
    </Box>
  );
}

export default SimpleSummarySection;