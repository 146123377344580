import React, { useState, useRef, useEffect } from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { styled, keyframes, css } from '@mui/material/styles';
import CasinoIcon from '@mui/icons-material/Casino';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

// 添加旋转动画
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

// 添加闪烁动画
const flash = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
`;

// 添加火花动画
const spark = keyframes`
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
  }
  50% {
    transform: translate(-50%, -50%) scale(1.5);
    opacity: 0.8;
  }
  100% {
    transform: translate(-50%, -50%) scale(2);
    opacity: 0;
  }
`;

// 修改花朵绽放动画
const flowerBloom = keyframes`
  0% {
    transform: translate(-50%, -50%) scale(0) rotate(0deg);
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(1) rotate(360deg);
    opacity: 0;
  }
`;

// 花朵上升动画
const flowerFloat = keyframes`
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  100% {
    transform: translateY(-100px);
    opacity: 0;
  }
`;

// 修改转轮组件样式
const Cylinder = styled('div')`
  width: 280px;
  height: 280px;
  border: 20px solid #444;
  border-radius: 50%;
  margin: 40px auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(145deg, #747474, #565656);
  box-shadow: inset 0 0 20px rgba(0,0,0,0.5);
  
  &::before {
    content: '';
    position: absolute;
    width: 40px;
    height: 40px;
    background: #333;
    border-radius: 50%;
    box-shadow: inset 0 0 10px rgba(0,0,0,0.8);
  }
  
  &.spinning {
    animation: ${rotate} 0.8s cubic-bezier(0.4, 0, 0.2, 1);
  }

  @media (max-width: 600px) {
    width: 220px;
    height: 220px;
    border-width: 15px;
    
    &::before {
      width: 30px;
      height: 30px;
    }
  }
`;

// 修改子弹仓样式
const Chamber = styled('div')`
  width: 45px;
  height: 45px;
  background: #2a2a2a;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform-origin: center;
  box-shadow: inset 0 0 10px rgba(0,0,0,0.8);
  margin: -22.5px;  /* 负的宽度/高度的一半，使其居中 */
  
  &.loaded {
    background: #ff0000;
  }

  @media (max-width: 600px) {
    width: 35px;
    height: 35px;
    margin: -17.5px;
  }
`;

// 修改火花效果组件
const SparkEffect = styled('div')`
  position: absolute;
  width: 100px;
  height: 100px;
  background: radial-gradient(circle, #ff6b6b, #ff4757);
  border-radius: 50%;
  animation: ${spark} 0.5s ease-out forwards;
  z-index: 1000;
  pointer-events: none;
  left: 50%;
  top: 50%;
  transform: translate(-50%, calc(-50% + 40px));
`;

// 修改花朵效果组件
const FlowerEffect = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1000;
  top: 140px;
`;

const Flower = styled('div')`
  position: absolute;
  font-size: 30px;
  animation: ${flowerFloat} 2s ease-out forwards;
  left: 50%;
  top: 50%;
  
  &::after {
    content: '🌸';
    position: absolute;
    left: 0;
    top: 0;
    animation: ${flowerBloom} 2s ease-out infinite;
  }
`;

// 结果提示文字动画
const resultFade = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const ResultText = styled(Typography)`
  animation: ${resultFade} 0.5s ease-out forwards;
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  z-index: 1001;
`;

// 修改按钮悬浮动画
const buttonPulse = css`${keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(220, 38, 38, 0.7);
  }
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 0 10px rgba(220, 38, 38, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(220, 38, 38, 0);
  }
`}`;

// 修改金属质感按钮样式
const MetallicButton = styled(Button)`
  position: relative;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: all 0.3s ease;
  overflow: hidden;
  
  ${props => props.variant === 'contained' ? `
    background: #1a1a1a;
    color: #fff;
    border: 2px solid #ff1a1a;
    box-shadow: 
      0 0 10px rgba(255, 26, 26, 0.3),
      inset 0 0 15px rgba(255, 26, 26, 0.2);
    
    &:hover:not(:disabled) {
      background: #2a1a1a;
      border-color: #ff3333;
      box-shadow: 
        0 0 20px rgba(255, 26, 26, 0.4),
        inset 0 0 20px rgba(255, 26, 26, 0.3);
      transform: translateY(-2px);
    }
    
    &:active:not(:disabled) {
      transform: translateY(1px);
    }
    
    &:disabled {
      background: #333;
      border-color: #444;
      color: #666;
      box-shadow: none;
    }
  ` : `
    background: #2d2d2d;
    color: #fff;
    border: 1px solid #4a4a4a;
    box-shadow: 
      0 2px 4px rgba(0, 0, 0, 0.2),
      inset 0 1px 1px rgba(255, 255, 255, 0.1);
    
    &:hover {
      background: #3d3d3d;
      border-color: #5a5a5a;
      box-shadow: 
        0 4px 8px rgba(0, 0, 0, 0.3),
        inset 0 1px 2px rgba(255, 255, 255, 0.2);
      transform: translateY(-1px);
    }

    &:active {
      transform: translateY(1px);
      box-shadow: 
        0 1px 2px rgba(0, 0, 0, 0.2),
        inset 0 1px 1px rgba(255, 255, 255, 0.1);
    }
  `}
`;

// 在文件顶部的 imports 下添加新的样式组件
const BulletIcon = styled('div')`
  width: 24px;
  height: 8px;
  background: linear-gradient(145deg, #ffd700, #b8860b);
  border-radius: 4px;
  position: relative;
  margin: 0 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  
  &::before {
    content: '';
    position: absolute;
    left: -4px;
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
    height: 6px;
    background: linear-gradient(145deg, #daa520, #cd853f);
    border-radius: 3px;
  }

  @media (max-width: 600px) {
    width: 20px;
    height: 7px;
    
    &::before {
      width: 7px;
      height: 5px;
      left: -3px;
    }
  }
`;

// 修改 BulletsContainer 样式，改为居中对齐
const BulletsContainer = styled(Box)`
  display: flex;
  justify-content: center;  // 改为居中对齐
  align-items: center;
  min-height: 40px;
  margin-bottom: 24px;
`;

// 修改子弹状态图标组件
const BulletStatusIcon = styled('div')`
  width: 24px;
  height: 24px;
  margin: 0 8px;  // 增加间距
  display: flex;
  justify-content: center;
  align-items: center;
  
  &.question {
    font-size: 20px;
    color: #666;
    font-weight: bold;
    &::after {
      content: '?';
    }
  }
  
  &.bullet {
    width: 24px;
    height: 8px;
    background: linear-gradient(145deg, #ffd700, #b8860b);
    border-radius: 4px;
    position: relative;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    
    &::before {
      content: '';
      position: absolute;
      left: -4px;
      top: 50%;
      transform: translateY(-50%);
      width: 8px;
      height: 6px;
      background: linear-gradient(145deg, #daa520, #cd853f);
      border-radius: 3px;
    }
  }
  
  &.flower {
    font-size: 20px;
    &::after {
      content: '🌸';
    }
  }

  @media (max-width: 600px) {
    margin: 0 6px;
    
    &.question {
      font-size: 16px;
    }
    
    &.bullet {
      width: 20px;
      height: 7px;
      
      &::before {
        width: 7px;
        height: 5px;
        left: -3px;
      }
    }
    
    &.flower {
      font-size: 16px;
    }
  }
`;

// Add these new keyframes after other keyframe definitions
const flipCard = keyframes`
  0% {
    transform: rotateY(0deg);
  }
  33% {
    transform: rotateY(180deg);
  }
  66% {
    transform: rotateY(360deg);
  }
  100% {
    transform: rotateY(540deg);
  }
`;

const unflipCard = keyframes`
  0% {
    transform: rotateY(540deg);
  }
  100% {
    transform: rotateY(720deg);
  }
`;

// Replace the existing PokerCard with this new version
const PokerCard = styled('div')`
  width: 120px;
  height: 180px;
  position: relative;
  perspective: 1000px;
  margin: 0 auto;
  
  @media (max-width: 600px) {
    width: 100px;
    height: 150px;
  }
`;

const CardInner = styled('div')`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  animation: ${props => props.isSpinning 
    ? props.showingBack 
      ? unflipCard
      : flipCard
    : 'none'} 1.6s ease-out forwards;
`;

const CardFace = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  font-weight: bold;
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
  
  @media (max-width: 600px) {
    font-size: 36px;
  }
`;

const CardFront = styled(CardFace)`
  background: linear-gradient(145deg, #ffffff, #f0f0f0);
  border: 2px solid #ddd;
  color: ${props => props.isRed ? '#ff0000' : '#000000'};
`;

const CardBack = styled(CardFace)`
  background: linear-gradient(145deg, #2b4b8c, #1a365d);
  border: 2px solid #1a365d;
  transform: rotateY(180deg);
  &::after {
    content: '🎮';
    font-size: 36px;
  }
`;

const PokerSection = styled(Box)`
  text-align: center;
  background: linear-gradient(145deg, #2d2d2d, #1a1a1a);
  padding: ${({ theme }) => theme.spacing(4)};
  border-radius: 16px;
  margin: ${({ theme }) => theme.spacing(2)};
  box-shadow: 0 8px 32px rgba(0,0,0,0.2);
  width: calc(100% - ${({ theme }) => theme.spacing(4)});
  max-width: 400px;
`;

const SectionContainer = styled(Box)`
  height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(2)};
  
  @media (max-width: 600px) {
    height: calc(100vh - 56px);
    padding: ${({ theme }) => theme.spacing(2, 1)};
  }
`;

const NavigationHint = styled(Box)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
  padding: 16px 8px;
  transition: all 0.3s ease;
  z-index: 10;
  cursor: pointer;
  
  &:hover {
    color: rgba(255, 255, 255, 0.8);
    background: rgba(0, 0, 0, 0.1);
  }
  
  &.left {
    left: 0;
    border-radius: 0 8px 8px 0;
  }
  
  &.right {
    right: 0;
    border-radius: 8px 0 0 8px;
  }

  @media (max-width: 600px) {
    padding: 12px 4px;
  }
`;

const SectionIndicator = styled(Box)`
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 8px;
  color: rgba(255, 255, 255, 0.7);
  z-index: 10;
  font-size: 0.9rem;
  
  &::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 2px;
    background: rgba(255, 255, 255, 0.3);
  }
`;

const SwipeIndicator = styled(Box)`
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
  animation: ${props => props.show ? css`${bounce} 2s infinite` : 'none'};
  opacity: ${props => props.show ? 1 : 0};
  transition: opacity 0.3s ease;
`;

// Add bounce animation
const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateX(-50%) translateY(0);
  }
  40% {
    transform: translateX(-50%) translateY(-10px);
  }
  60% {
    transform: translateX(-50%) translateY(-5px);
  }
`;

function RussianRoulette() {
  const [bullets, setBullets] = useState(6);
  const [realBulletPosition, setRealBulletPosition] = useState(Math.floor(Math.random() * 6));
  const [currentPosition, setCurrentPosition] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [showSparkEffect, setShowSparkEffect] = useState(false);
  const [showFlowerEffect, setShowFlowerEffect] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [resultText, setResultText] = useState('');
  const [currentRotation, setCurrentRotation] = useState(0);
  const [pokerCard, setPokerCard] = useState('?');  // single card instead of array
  const [isPokerSpinning, setIsPokerSpinning] = useState(false);
  const [showingBack, setShowingBack] = useState(true);
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [showSwipeHint, setShowSwipeHint] = useState(true);

  // 修改音效引用，使用完整的路径
  const gunshotSound = useRef(null);
  const dryfireSound = useRef(null);

  // 音效播放函数优化
  const playSound = async (sound) => {
    try {
      if (sound.current) {
        sound.current.currentTime = 0;
        sound.current.volume = 0.5;
        await sound.current.play();
      }
    } catch (err) {
      console.log('Audio play failed:', err);
    }
  };

  // 添加新的 state 来跟踪每个位置的状态
  const [bulletStatuses, setBulletStatuses] = useState(Array(6).fill('question'));

  const handleShoot = () => {
    if (isSpinning || gameOver) return;
    
    setIsSpinning(true);
    
    const rotations = (Math.floor(Math.random() * 3) + 2) * 360;
    const extraRotation = 60 * currentPosition;
    const newRotation = currentRotation + rotations + extraRotation;
    
    setCurrentRotation(newRotation);
    
    setTimeout(() => {
      setIsSpinning(false);
      
      const normalizedPosition = (currentPosition + 1) % 6;
      const isLost = normalizedPosition === realBulletPosition;
      
      // 更新子弹状态
      setBulletStatuses(prev => {
        const newStatuses = [...prev];
        newStatuses[currentPosition] = isLost ? 'bullet' : 'flower';
        return newStatuses;
      });
      
      if (isLost) {
        playSound(gunshotSound);
        setShowSparkEffect(true);
        setGameOver(true);
        setResultText('Game Over!');
        setTimeout(() => {
          setShowSparkEffect(false);
          setShowResult(true);
        }, 500);
      } else {
        playSound(dryfireSound);
        setShowFlowerEffect(true);
        setBullets(prev => prev - 1);
        setCurrentPosition(normalizedPosition);
        setResultText('Safe!');
        setTimeout(() => {
          setShowFlowerEffect(false);
          setShowResult(true);
        }, 1000);
      }

      setTimeout(() => {
        setShowResult(false);
      }, 3000);
    }, 800);
  };

  const handleReset = () => {
    setBullets(6);
    setCurrentPosition(0);
    setGameOver(false);
    setShowResult(false);
    setShowSparkEffect(false);
    setShowFlowerEffect(false);
    setRealBulletPosition(Math.floor(Math.random() * 6));
    setCurrentRotation(0);
    setBulletStatuses(Array(6).fill('question')); // 重置子弹状态
  };

  const renderChambers = () => {
    const radius = 80;
    return Array(6).fill(0).map((_, index) => {
      const angle = (index * 60) * (Math.PI / 180);
      const x = radius * Math.cos(angle);
      const y = radius * Math.sin(angle);
      
      // 只在游戏结束时显示子弹位置
      const isLoaded = gameOver && index === realBulletPosition;
      
      return (
        <Chamber
          key={index}
          style={{
            transform: `translate(${x}px, ${y}px)`,
          }}
          className={isLoaded ? 'loaded' : ''}
        />
      );
    });
  };

  // 修改花朵渲染函数
  const renderFlowers = () => {
    return Array(5).fill(0).map((_, index) => {
      const angle = (index * 72) * (Math.PI / 180);
      const radius = 40;
      const x = radius * Math.cos(angle);
      const y = radius * Math.sin(angle);
      const randomDelay = index * 0.2;
      
      return (
        <Flower
          key={index}
          style={{
            transform: `translate(calc(-50% + ${x}px), calc(-50% + ${y}px))`,
            animationDelay: `${randomDelay}s`
          }}
        />
      );
    });
  };

  // 修改音频初始化
  useEffect(() => {
    // 创建音频实例
    gunshotSound.current = new Audio('/sounds/gunshot.mp3');
    dryfireSound.current = new Audio('/sounds/dryfire.mp3');

    // 预加载音频
    const loadAudio = async () => {
      try {
        await gunshotSound.current.load();
        await dryfireSound.current.load();
        
        // 设置音频属性
        gunshotSound.current.preload = 'auto';
        dryfireSound.current.preload = 'auto';
      } catch (err) {
        console.log('Audio load failed:', err);
      }
    };

    loadAudio();

    // 清理函数
    return () => {
      if (gunshotSound.current) {
        gunshotSound.current.pause();
        gunshotSound.current = null;
      }
      if (dryfireSound.current) {
        dryfireSound.current.pause();
        dryfireSound.current = null;
      }
    };
  }, []);

  // 添加用户交互初始化音频的处理
  useEffect(() => {
    const initAudio = () => {
      if (gunshotSound.current && dryfireSound.current) {
        // 尝试播放并立即暂停，以初始化音频
        gunshotSound.current.play().then(() => {
          gunshotSound.current.pause();
          gunshotSound.current.currentTime = 0;
        }).catch(err => console.log('Init audio failed:', err));
        
        dryfireSound.current.play().then(() => {
          dryfireSound.current.pause();
          dryfireSound.current.currentTime = 0;
        }).catch(err => console.log('Init audio failed:', err));
      }
      // 移除事件监听器
      document.removeEventListener('click', initAudio);
    };

    // 添加点击事件监听器
    document.addEventListener('click', initAudio);
    return () => document.removeEventListener('click', initAudio);
  }, []);

  const handlePokerLottery = () => {
    if (isPokerSpinning) return;
    
    setIsPokerSpinning(true);
    setShowingBack(false); // Start showing front
    
    const possibleCards = ['A', 'K', 'Q'];
    
    setTimeout(() => {
      const newCard = possibleCards[Math.floor(Math.random() * possibleCards.length)];
      setPokerCard(newCard);
      
      // Flip back after showing the result
      setTimeout(() => {
        setShowingBack(true);
        setIsPokerSpinning(false);
      }, 500);
    }, 1100);
  };

  // Add this effect to hide swipe hint after first swipe
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSwipeHint(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Container 
      maxWidth="sm" 
      sx={{ 
        height: '100vh',
        p: 0,
        overflow: 'hidden',
        position: 'relative',
        maxWidth: '600px !important',
      }}
    >
      <SectionIndicator>
        {currentSlide === 0 ? 'Russian Roulette' : 'Poker Lottery'}
      </SectionIndicator>

      {currentSlide === 0 && (
        <NavigationHint 
          className="right"
          onClick={() => swiperInstance?.slideNext()}
        >
          <ArrowForwardIosIcon />
        </NavigationHint>
      )}

      {currentSlide === 1 && (
        <NavigationHint 
          className="left"
          onClick={() => swiperInstance?.slidePrev()}
        >
          <ArrowBackIosIcon />
        </NavigationHint>
      )}

      <SwipeIndicator show={showSwipeHint}>
        <Typography variant="caption" sx={{ mb: 1 }}>
          Swipe to see more
        </Typography>
        <ArrowForwardIosIcon sx={{ fontSize: '1.2rem' }} />
      </SwipeIndicator>

      <Swiper
        modules={[Pagination]}
        pagination={{
          clickable: true,
          bulletActiveClass: 'swiper-pagination-bullet-active',
          bulletClass: 'swiper-pagination-bullet',
        }}
        spaceBetween={0}
        slidesPerView={1}
        onSwiper={setSwiperInstance}
        onSlideChange={(swiper) => {
          setCurrentSlide(swiper.activeIndex);
          setShowSwipeHint(false);
        }}
        style={{
          height: '100%',
          '--swiper-pagination-color': '#ff4757',
          '--swiper-pagination-bullet-inactive-color': '#999999',
          '--swiper-pagination-bullet-inactive-opacity': '0.5',
          '--swiper-pagination-bullet-size': '10px',
          '--swiper-pagination-bullet-horizontal-gap': '6px'
        }}
      >
        <SwiperSlide>
          <SectionContainer>
            {/* Roulette Section */}
            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
              height: '100%',
              justifyContent: 'center'
            }}>
              {showResult && (
                <ResultText
                  sx={{
                    color: gameOver ? '#ff4757' : '#2ecc71',
                    top: { xs: '10px', sm: '20px' }
                  }}
                >
                  {resultText}
                </ResultText>
              )}

              <Box sx={{ 
                textAlign: 'center',
                background: 'linear-gradient(145deg, #f5f5f5, #e6e6e6)',
                padding: { xs: 2, sm: 4 },
                borderRadius: 3,
                boxShadow: '0 8px 32px rgba(0,0,0,0.1)',
                position: 'relative',
              }}>
                <Typography 
                  variant="h4" 
                  component="h1" 
                  gutterBottom 
                  sx={{ 
                    color: '#333',
                    fontWeight: 'bold',
                    mb: 3,
                    fontSize: { xs: '1.8rem', sm: '2.4rem' }
                  }}
                >
                  Russian Roulette
                </Typography>
                
                <Box sx={{ position: 'relative' }}>
                  {showSparkEffect && <SparkEffect />}
                  {showFlowerEffect && (
                    <FlowerEffect>
                      {renderFlowers()}
                    </FlowerEffect>
                  )}
                  
                  <Cylinder 
                    className={isSpinning ? 'spinning' : ''} 
                    style={{
                      transform: `rotate(${currentRotation}deg)`,
                      transition: isSpinning ? 'transform 0.8s cubic-bezier(0.4, 0, 0.2, 1)' : 'none'
                    }}
                  >
                    {renderChambers()}
                  </Cylinder>
                </Box>
                
                <BulletsContainer>
                  {[...Array(6)].map((_, index) => (
                    <BulletStatusIcon 
                      key={index}
                      className={bulletStatuses[index]}
                    />
                  ))}
                </BulletsContainer>

                <Box sx={{ 
                  display: 'flex', 
                  gap: 2,
                  justifyContent: 'center',
                  flexDirection: { xs: 'column', sm: 'row' },
                  alignItems: 'center',
                  px: { xs: 2, sm: 0 }
                }}>
                  <MetallicButton 
                    variant="contained"
                    onClick={handleShoot}
                    disabled={gameOver || currentPosition >= 6}
                    sx={{
                      fontSize: { xs: '1.2rem', sm: '1.4rem' },
                      padding: '16px 40px',
                      minWidth: '180px',
                      borderRadius: '4px',
                      fontWeight: 'bold',
                      textShadow: '0 0 5px rgba(255,0,0,0.3)',
                    }}
                  >
                    Pull Trigger
                  </MetallicButton>
                  <MetallicButton 
                    variant="outlined"
                    onClick={handleReset}
                    sx={{
                      fontSize: { xs: '0.85rem', sm: '0.9rem' },
                      padding: '8px 20px',
                      minWidth: '120px',
                      borderRadius: '4px',
                      fontWeight: 'medium',
                      '&:hover': {
                        transform: 'translateY(-1px)'
                      }
                    }}
                  >
                    Reset Game
                  </MetallicButton>
                </Box>
              </Box>
            </Box>
          </SectionContainer>
        </SwiperSlide>

        <SwiperSlide>
          <SectionContainer>
            {/* Poker Section */}
            <PokerSection sx={{ 
              height: 'auto',
              maxHeight: '90vh',
              overflowY: 'auto',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: { xs: '85%', sm: '400px' },
              mx: 'auto',
              my: 2,
              position: 'relative',
              left: '50%',
              transform: 'translateX(-50%)',
            }}>
              <Typography 
                variant="h5" 
                component="h2" 
                gutterBottom 
                sx={{ 
                  color: '#fff',
                  fontWeight: 'bold',
                  mb: 3,
                  fontSize: { xs: '1.5rem', sm: '1.8rem' }
                }}
              >
                Poker Lottery
              </Typography>
              
              <Box sx={{ mb: 3 }}>
                <PokerCard>
                  <CardInner 
                    isSpinning={isPokerSpinning}
                    showingBack={showingBack}
                  >
                    <CardFront>
                      {isPokerSpinning ? '?' : pokerCard}
                    </CardFront>
                    <CardBack />
                  </CardInner>
                </PokerCard>
              </Box>

              <MetallicButton
                variant="contained"
                onClick={handlePokerLottery}
                disabled={isPokerSpinning}
                startIcon={<CasinoIcon />}
                sx={{
                  fontSize: { xs: '0.85rem', sm: '0.9rem' },
                  padding: '12px 24px',
                  minWidth: '160px',
                  borderRadius: '8px',
                  fontWeight: 'medium',
                  background: 'linear-gradient(145deg, #3a3a3a, #2a2a2a)',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    background: 'linear-gradient(145deg, #444444, #333333)',
                  }
                }}
              >
                Draw Card
              </MetallicButton>
            </PokerSection>
          </SectionContainer>
        </SwiperSlide>
      </Swiper>
    </Container>
  );
}

export default RussianRoulette;