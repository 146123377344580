import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Paper,
  CircularProgress,
  Alert,
  Menu as MenuComponent,
  MenuItem,
  Snackbar,
  ListItemIcon,
  ListItemText,
  useTheme,
  useMediaQuery,
  Fab,
  LinearProgress,
} from '@mui/material';
import {
  ArrowBack,
  ArrowForward,
  NavigateBefore,
  NavigateNext,
  Menu as MenuIcon,
  Home,
  Refresh,
} from '@mui/icons-material';
import { fetchNovelContent } from '../../services/api';

export default function NovelReader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const location = useLocation();
  
  const [content, setContent] = useState('');
  const [nextUrl, setNextUrl] = useState('');
  const [prevUrl, setPrevUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [progress, setProgress] = useState(0);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const [bookName, setBookName] = useState('');
  const [chapterName, setChapterName] = useState('');
  const progressTimer = useRef(null);
  const loadingTimer = useRef(null);

  const urlParams = new URLSearchParams(location.search);
  const currentUrl = decodeURIComponent(urlParams.get('url') || '');

  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [currentUrl]);

  useEffect(() => {
    if (currentUrl) {
      fetchContent();
    } else {
      navigate('/novel');
    }
  }, [currentUrl, navigate]);

  const startFakeProgress = () => {
    let currentProgress = 0;
    
    // Clear any existing timer
    if (progressTimer.current) {
      clearInterval(progressTimer.current);
    }
    
    // Reset progress
    setProgress(0);
    
    // Start new progress animation
    progressTimer.current = setInterval(() => {
      // Random increment between 1 and 4
      const increment = Math.random() * 0.5 + 0.2;
      currentProgress += increment;
      
      if (currentProgress >= 99) {
        clearInterval(progressTimer.current);
        setProgress(99);
      } else {
        setProgress(Math.min(currentProgress, 99));
      }
    }, 120); // Update every 120ms
  };

  const fetchContent = async (refresh = false) => {
    setLoading(true);
    
    // Clear any existing loading timer
    if (loadingTimer.current) {
      clearTimeout(loadingTimer.current);
    }
    
    // Only show loading UI after 1.5s
    loadingTimer.current = setTimeout(() => {
      setShowLoading(true);
      startFakeProgress();
    }, 1500);
    
    // Scroll to top when starting to load
    window.scrollTo(0, 0);
    
    try {
      let response;
      
      // Try to get from cache first if not refreshing
      if (!refresh) {
        const cached = localStorage.getItem(`novel_content_${currentUrl}`);
        if (cached) {
          try {
            response = JSON.parse(cached);
            console.log('[Debug] Using cached content for:', currentUrl);
            setIsFirstLoad(false);
          } catch (e) {
            console.error('[Debug] Error parsing cached content:', e);
          }
        }
      }
      
      // If not in cache or refreshing, fetch from server
      if (!response || refresh) {
        console.log('[Debug] Fetching fresh content for:', currentUrl);
        response = await fetchNovelContent(currentUrl, refresh);
      }
      
      if (response?.content) {
        // Cache the response
        try {
          localStorage.setItem(`novel_content_${currentUrl}`, JSON.stringify(response));
          console.log('[Debug] Content cached for:', currentUrl);
        } catch (e) {
          console.error('[Debug] Error caching content:', e);
        }

        // Update book and chapter names
        setBookName(response.bookName || '');
        setChapterName(response.chapterName || '');

        // Define all types of spaces we want to match
        const spacePattern = /([　 \t]{2,}|  +)/g;  // Matches 2+ of any space type
        
        // Replace all types of consecutive spaces with newline
        let processedContent = response.content
          .replace(spacePattern, '\n')
          .split('\n')
          .map(line => line.trim())  // Trim any remaining single spaces
          .filter(Boolean)
          .join('\n');
          
        setContent(processedContent);
        setNextUrl(response?.nextUrl);
        setPrevUrl(response?.prevUrl);
      }
    } catch (error) {
      console.error('Error fetching content:', error);
      setError('Failed to load content. Please try again.');
    } finally {
      // Clear timers
      if (progressTimer.current) {
        clearInterval(progressTimer.current);
      }
      if (loadingTimer.current) {
        clearTimeout(loadingTimer.current);
      }
      // Set progress to 100% when done
      setProgress(100);
      // Reset progress and loading state after animation
      setTimeout(() => {
        setProgress(0);
        setShowLoading(false);
        setLoading(false);
      }, 500);
    }
  };

  const processContent = (content) => {
    if (!content) return '';
    
    // Split content into lines
    return content
      .split('\n')
      .filter(line => {
        // Remove empty or whitespace-only lines
        if (!line.trim()) return false;
        
        // Remove lines with filter words
        const filterWords = [
          // Navigation words
          '上一页', '下一页', '上一章', '下一章',
          '前一页', '後一頁', '上一頁', '下一頁',
          '前一章', '後一章', '上一章', '下一章',
          'Previous Chapter', 'Next Chapter',
          'Previous Page', 'Next Page',
          'Prev Chapter', 'Next Chapter',
          'Prev Page', 'Next Page',
          // Common ad words
          '广告', '廣告', 'Advertisement',
          '最新章节', '最新章節',
          '手机版', '手機版', 'Mobile Version',
          '请记住本站', '請記住本站',
          '收藏本站', '加入收藏',
          '推荐本书', '推薦本書',
          '本章未完', '未完待续', '未完待續',
          '加载中', '載入中', 'Loading',
          '更新时间', '更新時間', 'Last Updated',
          '字数：', '字數：', 'Word Count:',
          // Common site names or URLs
          'www.', 'http://', 'https://', '本书目录',':', "必读推荐","关于",
          // Add more filter words as needed
          'try{cnt1();}catch(err){}'
        ];
        
        const trimmedLine = line.trim().toLowerCase();
        return !filterWords.some(text => 
          trimmedLine === text.toLowerCase() ||
          trimmedLine.startsWith(`${text.toLowerCase()} `) ||
          trimmedLine.endsWith(` ${text.toLowerCase()}`)
        );
      })
      .join('\n');
  };

  const handleNavigation = (targetUrl) => {
    if (!targetUrl) return;
    
    try {
      // Get the current URL's domain
      const currentUrl = new URL(urlParams.get('url'));
      
      // Try to parse the target URL
      let fullUrl;
      try {
        fullUrl = new URL(targetUrl);
      } catch {
        // If parsing fails, it's a relative URL
        // Handle both /path and //domain.com/path cases
        if (targetUrl.startsWith('//')) {
          fullUrl = new URL(`${currentUrl.protocol}${targetUrl}`);
        } else if (targetUrl.startsWith('/')) {
          fullUrl = new URL(`${currentUrl.origin}${targetUrl}`);
        } else {
          fullUrl = new URL(`${currentUrl.origin}/${targetUrl}`);
        }
      }
      
      navigate(`/novel/read?url=${encodeURIComponent(fullUrl.href)}`);
    } catch (error) {
      console.error('Error handling navigation:', error);
    }
  };

  const handleRefresh = () => {
    if (!currentUrl) return;
    fetchContent(true);
    setShowSnackbar(true);
    setSnackbarMessage('Refreshing content...');
  };


  const handleMenuClick = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleHomeClick = () => {
    navigate('/novel');
    handleMenuClose();
  };

  const handleSnackbarClose = () => {
    setShowSnackbar(false);
    setSnackbarMessage('');
  };

  return (
    <Box sx={{ minHeight: '100vh' }}>
      {/* App Bar */}
      <AppBar 
        position="fixed" 
        color="primary"
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleMenuClick}
          >
            <MenuIcon />
          </IconButton>

          <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', ml: 2 }}>
            {bookName && (
              <Typography variant="subtitle2" component="div" noWrap>
                {bookName}
              </Typography>
            )}
            <Typography variant="body2" component="div" noWrap>
              {chapterName || ''}
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Menu */}
      <MenuComponent
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleHomeClick}>
          <Home sx={{ mr: 1 }} /> Home
        </MenuItem>
        <MenuItem onClick={handleRefresh}>
          <Refresh sx={{ mr: 1 }} /> Refresh
        </MenuItem>
      </MenuComponent>

      {/* Content */}
      <Box 
        ref={contentRef}
        sx={{ 
          px: { xs: 1, sm: 2 },
          maxWidth: '800px',
          mx: 'auto',
          py: 1,
        }}
      >
        {loading ? (
          <Box 
            sx={{ 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: '60vh',
              gap: 1,
            }}
          >
            {showLoading ? (
              <>
                <Box sx={{ width: '100%', maxWidth: 400 }}>
                  <LinearProgress 
                    variant="determinate" 
                    value={progress} 
                    sx={{ 
                      height: 8,
                      borderRadius: 4,
                      '& .MuiLinearProgress-bar': {
                        borderRadius: 4,
                      }
                    }}
                  />
                  <Typography 
                    variant="body2" 
                    color="text.secondary"
                    align="center"
                    sx={{ mt: 1 }}
                  >
                    {progress < 100 ? `Loading... ${Math.round(progress)}%` : 'Almost done!'}
                  </Typography>
                </Box>
                {isFirstLoad && (
                  <Alert severity="info" sx={{ maxWidth: 400 }}>
                    First time loading may take longer. Future visits will be faster due to caching.
                  </Alert>
                )}
              </>
            ) : (
              <CircularProgress size={40} />
            )}
          </Box>
        ) : error ? (
          <Alert 
            severity="error" 
            sx={{ 
              mt: 4,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {error}
          </Alert>
        ) : (
          <Paper
            sx={{
              p: { xs: 2, sm: 3 },
              my: 0,
              borderRadius: 2,
              bgcolor: theme.palette.background.paper,
              boxShadow: theme.shadows[2],
            }}
          >
            <Box sx={{ 
              whiteSpace: 'pre-line', 
              lineHeight: 1.8,
              '& p': {
                marginBottom: '1.5em',
                '&:last-child': {
                  marginBottom: 0
                }
              }
            }}>
              {content.split('\n').map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))}
            </Box>
            
            {/* Source Link */}
            <Box 
              sx={{ 
                mt: 3, 
                pt: 2, 
                borderTop: 1, 
                borderColor: 'divider',
                textAlign: 'right'
              }}
            >
              <Typography 
                variant="body2" 
                color="text.secondary"
                component="a"
                href={currentUrl}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ 
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline'
                  }
                }}
              >
                Source
              </Typography>
            </Box>
          </Paper>
        )}
      </Box>

      {/* Navigation Buttons */}
      {!loading && (prevUrl || nextUrl) && (
        <Box
          sx={{
            position: 'fixed',
            bottom: { xs: 16, sm: 32 },
            left: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'center',
            gap: 2,
            zIndex: 1000,
          }}
        >
          {prevUrl && (
            <Fab
              color="primary"
              onClick={() => handleNavigation(prevUrl)}
              sx={{
                boxShadow: theme.shadows[8],
              }}
            >
              {isMobile ? <NavigateBefore /> : <ArrowBack />}
            </Fab>
          )}
          {nextUrl && (
            <Fab
              color="primary"
              onClick={() => handleNavigation(nextUrl)}
              sx={{
                boxShadow: theme.shadows[8],
              }}
            >
              {isMobile ? <NavigateNext /> : <ArrowForward />}
            </Fab>
          )}
        </Box>
      )}

      {/* Snackbar */}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </Box>
  );
}
