import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';

function DeleteCategoryConfirmationModal({ isOpen, onClose, onConfirm }) {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="category-delete-dialog-title"
      aria-describedby="category-delete-dialog-description"
    >
      <DialogTitle id="category-delete-dialog-title">{"Confirm Delete Category"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="category-delete-dialog-description">
          Are you sure you want to delete this category? This action cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onConfirm} color="error" autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteCategoryConfirmationModal;