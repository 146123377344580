import React, { useState, useEffect, useRef } from 'react';
import { 
  Container, 
  Paper, 
  Typography, 
  TextField, 
  Button, 
  Box, 
  Alert, 
  Avatar, 
  Divider,
  CircularProgress,
  IconButton,
  Tooltip,
  Input,
  Dialog
} from '@mui/material';
import { PhotoCamera, Save } from '@mui/icons-material';
import { updateUserProfile, getUserProfile } from '../services/api';
import axios from 'axios';

function Profile() {
  const [profile, setProfile] = useState({
    username: '',
    nickname: '',
    avatarUrl: ''
  });
  const [message, setMessage] = useState({ type: '', content: '' });
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef(null);
  const [previewOpen, setPreviewOpen] = useState(false);

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const fetchUserProfile = async () => {
    try {
      setLoading(true);
      const data = await getUserProfile();
      setProfile({
        username: data.username,
        nickname: data.nickname || '',
        avatarUrl: data.avatarUrl || ''
      });
    } catch (error) {
      setMessage({ type: 'error', content: 'Failed to fetch profile' });
    } finally {
      setLoading(false);
    }
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Validate file type
    if (!file.type.startsWith('image/')) {
      setMessage({ type: 'error', content: 'Please upload an image file' });
      return;
    }

    // Validate file size (5MB)
    if (file.size > 5 * 1024 * 1024) {
      setMessage({ type: 'error', content: 'Image size should be less than 5MB' });
      return;
    }

    try {
      setUploading(true);
      const formData = new FormData();
      formData.append('image', file);

      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${process.env.REACT_APP_REQUEST_DOMAIN}/api/media/upload`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      setProfile(prev => ({
        ...prev,
        avatarUrl: response.data.imageUrl
      }));
      
      // Auto-save the profile with new avatar URL
      await updateUserProfile({ avatarUrl: response.data.imageUrl });
      setMessage({ type: 'success', content: 'Avatar updated successfully' });
    } catch (error) {
      setMessage({ 
        type: 'error', 
        content: error.response?.data?.error || 'Failed to upload image' 
      });
    } finally {
      setUploading(false);
    }
  };

  const handleAvatarClick = () => {
    if (profile.avatarUrl) {
      setPreviewOpen(true);
    } else {
      fileInputRef.current?.click();
    }
  };

  const handlePreviewClose = () => {
    setPreviewOpen(false);
  };

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  const handleChange = (e) => {
    setProfile({
      ...profile,
      [e.target.name]: e.target.value
    });
    // Clear any error messages when user starts typing
    setMessage({ type: '', content: '' });
  };

  const validateInputs = () => {
    if (profile.nickname && profile.nickname.length > 30) {
      setMessage({ type: 'error', content: 'Nickname must be less than 30 characters' });
      return false;
    }
    if (profile.avatarUrl && !isValidUrl(profile.avatarUrl)) {
      setMessage({ type: 'error', content: 'Please enter a valid URL for avatar' });
      return false;
    }
    return true;
  };

  const isValidUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateInputs()) return;

    try {
      setSaving(true);
      const updateData = {
        ...(profile.nickname && { nickname: profile.nickname }),
        ...(profile.avatarUrl && { avatarUrl: profile.avatarUrl })
      };

      await updateUserProfile(updateData);
      setMessage({ type: 'success', content: 'Profile updated successfully' });
    } catch (error) {
      setMessage({ 
        type: 'error', 
        content: error.response?.data?.error || 'Failed to update profile' 
      });
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <Container maxWidth="sm" sx={{ mt: 12, display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="sm" sx={{ mt: 12, mb: 4 }}>
      <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center', 
          mb: 4 
        }}>
          <Box sx={{ position: 'relative' }}>
            <Avatar
              src={profile.avatarUrl}
              alt={profile.username}
              sx={{ 
                width: 120, 
                height: 120, 
                mb: 2,
                border: '4px solid white',
                boxShadow: 3,
                cursor: 'pointer',
                '&:hover': {
                  opacity: 0.8
                }
              }}
              onClick={handleAvatarClick}
            />
            <Input
              type="file"
              inputRef={fileInputRef}
              sx={{ display: 'none' }}
              onChange={handleImageUpload}
              accept="image/*"
            />
            <Tooltip title={uploading ? 'Uploading...' : 'Upload avatar'}>
              <IconButton 
                sx={{ 
                  position: 'absolute',
                  bottom: 10,
                  right: -10,
                  backgroundColor: 'background.paper',
                  boxShadow: 2,
                  '&:hover': { backgroundColor: 'background.default' }
                }}
                size="small"
                onClick={handleUploadClick}
                disabled={uploading}
              >
                {uploading ? <CircularProgress size={20} /> : <PhotoCamera />}
              </IconButton>
            </Tooltip>
          </Box>
          <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }}>
            Profile Settings
          </Typography>
        </Box>
        
        {message.content && (
          <Alert 
            severity={message.type} 
            sx={{ mb: 3 }}
            onClose={() => setMessage({ type: '', content: '' })}
          >
            {message.content}
          </Alert>
        )}

        <Divider sx={{ mb: 3 }} />

        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
          <TextField
            fullWidth
            label="Username"
            name="username"
            value={profile.username}
            disabled
            margin="normal"
            helperText="Username cannot be changed"
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Nickname"
            name="nickname"
            value={profile.nickname}
            onChange={handleChange}
            margin="normal"
            helperText="Maximum 30 characters"
            inputProps={{ maxLength: 30 }}
            sx={{ mb: 2 }}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={saving}
            startIcon={saving ? <CircularProgress size={20} /> : <Save />}
            sx={{ 
              mt: 2,
              py: 1.5,
              fontWeight: 'bold',
              borderRadius: 2
            }}
          >
            {saving ? 'Saving...' : 'Save Changes'}
          </Button>
        </Box>
      </Paper>
      <Dialog
        open={previewOpen}
        onClose={handlePreviewClose}
        maxWidth="md"
        PaperProps={{
          sx: {
            borderRadius: 2,
            overflow: 'hidden'
          }
        }}
      >
        <Box
          component="img"
          src={profile.avatarUrl}
          alt={profile.username}
          sx={{
            width: '100%',
            height: 'auto',
            maxHeight: '80vh',
            objectFit: 'contain'
          }}
        />
      </Dialog>
    </Container>
  );
}

export default Profile; 