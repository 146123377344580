import React from 'react';
import { Box, Paper, Typography, Accordion, AccordionSummary, AccordionDetails, useTheme, useMediaQuery, IconButton, Tooltip as MuiTooltip, Snackbar, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PsychologyIcon from '@mui/icons-material/Psychology';
import { Pie } from 'react-chartjs-2';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// 注册必要的 Chart.js 组件和插件
import { Chart as ChartJS, ArcElement, Tooltip as ChartTooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, ChartTooltip, Legend, ChartDataLabels);

// 在文件开头添加一个新的辅助函数
const openChatGPT = () => {
  // 检查是否为移动设备
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  
  if (isMobile) {
    // 尝试打开 ChatGPT 应用
    window.location.href = 'chatgpt://new-chat';
  } else {
    // 桌面端直接打开网页版
    window.open('https://chat.openai.com', '_blank');
  }
};

function SummarySection({ calculateTotal, calculateBalance, calculateDailyAverage, exchangeRate, transactions, convertToUSD, startDate, endDate }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const preparePieChartData = () => {
    const incomeTotals = {};
    const expenseTotals = {};
    let totalIncome = 0;
    let totalExpense = 0;

    transactions.forEach(transaction => {
      const amount = convertToUSD(transaction.amount, transaction.currency);
      if (transaction.type === 'income') {
        if (!incomeTotals[transaction.categoryModel?.name]) {
          incomeTotals[transaction.categoryModel?.name] = 0;
        }
        incomeTotals[transaction.categoryModel?.name] += amount;
        totalIncome += amount;
      } else {
        if (!expenseTotals[transaction.categoryModel?.name]) {
          expenseTotals[transaction.categoryModel?.name] = 0;
        }
        expenseTotals[transaction.categoryModel?.name] += amount;
        totalExpense += amount;
      }
    });

    // 对数据进行排序的辅助函数
    const sortData = (data) => {
      return Object.entries(data)
        .sort((a, b) => b[1] - a[1])
        .reduce((acc, [key, value]) => {
          acc.labels.push(key);
          acc.data.push(value);
          return acc;
        }, { labels: [], data: [] });
    };

    const sortedIncome = sortData(incomeTotals);
    const sortedExpense = sortData(expenseTotals);

    const overviewData = {
      labels: ['Income', 'Expense'],
      datasets: [{
        data: [totalIncome, totalExpense],
        backgroundColor: ['#66BB6A', '#EF5350'],  // 柔和一些的绿色表示收入，稍柔和的红色表示支出
        hoverBackgroundColor: ['#5C9E58', '#E53935'],  // 悬停时的更深绿色和深红色
      }],
    };

    const incomeColors = [
      '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF',
      '#FF9F40', '#FF5733', '#C70039', '#900C3F', '#581845',
      '#FFC300', '#DAF7A6', '#FF5733', '#C70039', '#900C3F'
    ];

    const incomeHoverColors = [
      '#FF4D75', '#3492E5', '#FFB347', '#42B3B3', '#8A58FF',
      '#FF8F33', '#FF4500', '#B3002D', '#7A0A37', '#4A143B',
      '#E6B000', '#C4E690', '#FF4500', '#B3002D', '#7A0A37'
    ];

    const expenseColors = [
      '#F44336', '#2196F3', '#FFC107', '#4CAF50', '#9C27B0',
      '#00BCD4', '#FF5722', '#795548', '#607D8B', '#E91E63',
      '#3F51B5', '#009688', '#FFEB3B', '#8BC34A', '#673AB7'
    ];

    const expenseHoverColors = [
      '#E53935', '#1E88E5', '#FFB300', '#43A047', '#8E24AA',
      '#00ACC1', '#F4511E', '#6D4C41', '#546E7A', '#D81B60',
      '#3949AB', '#00897B', '#FDD835', '#7CB342', '#5E35B1'
    ];

    const incomeData = {
      labels: sortedIncome.labels,
      datasets: [{
        data: sortedIncome.data,
        backgroundColor: incomeColors,
        hoverBackgroundColor: incomeHoverColors,
      }],
    };

    const expenseData = {
      labels: sortedExpense.labels,
      datasets: [{
        data: sortedExpense.data,
        backgroundColor: expenseColors,
        hoverBackgroundColor: expenseHoverColors,
      }],
    };

    return { overviewData, incomeData, expenseData };
  };

  const PieChartBox = ({ data, title }) => (
    <Box sx={{ 
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      p: 2,
      height: '100%',
      justifyContent: 'center',
      mb: 2
    }}>
      <Typography variant="subtitle1" align="center" sx={{ mb: 1 }}>{title}</Typography>
      <Box sx={{ 
        height: 300, 
        width: '100%', 
        maxWidth: 300,
        margin: '0 auto'
      }}>
        <Pie data={data} options={{ 
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'bottom',
              labels: {
                boxWidth: 12,
                padding: 10
              }
            },
            tooltip: {
              callbacks: {
                label: function(context) {
                  let label = context.label || '';
                  if (label) {
                    label += ': ';
                  }
                  if (context.parsed !== undefined) {
                    label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed);
                    label += ` (${(context.parsed / context.dataset.data.reduce((a, b) => a + b) * 100).toFixed(2)}%)`;
                  }
                  return label;
                }
              }
            },
            datalabels: {
              color: '#fff',
              font: {
                weight: 'bold',
                size: 12
              },
              formatter: (value, ctx) => {
                const dataset = ctx.chart.data.datasets[0];
                const total = dataset.data.reduce((acc, data) => acc + data, 0);
                const percentage = ((value / total) * 100).toFixed(1) + '%';
                return percentage;
              }
            }
          },
          elements: {
            arc: {
              borderWidth: 2,
              borderColor: '#fff'
            }
          },
          layout: {
            padding: 20
          }
        }} />
      </Box>
    </Box>
  );

  const pieChartData = preparePieChartData();
  const charts = [
    { data: pieChartData.overviewData, title: "Overview" },
    { data: pieChartData.expenseData, title: "Expense Categories" },
    { data: pieChartData.incomeData, title: "Income Categories" }
  ];
  
  const AccountInfo = () => {
    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 3 }}>
        <Paper elevation={1} sx={{ flex: 1, p: 2, minWidth: '100px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <TrendingUpIcon color="success" sx={{ mr: 1 }} />
            <Typography variant="subtitle1">Income</Typography>
          </Box>
          <Typography variant="h6" color="success.main">
            ${calculateTotal('income')}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            Daily Avg: ${calculateDailyAverage('income')}
          </Typography>
        </Paper>
        <Paper elevation={1} sx={{ flex: 1, p: 2, minWidth: '100px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <TrendingDownIcon color="error" sx={{ mr: 1 }} />
            <Typography variant="subtitle1">Expenses</Typography>
          </Box>
          <Typography variant="h6" color="error.main">
            ${calculateTotal('expense')}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            Daily Avg: ${calculateDailyAverage('expense')}
          </Typography>
        </Paper>
        <Paper elevation={1} sx={{ flex: 1, p: 2, minWidth: '100px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <AccountBalanceWalletIcon color="primary" sx={{ mr: 1 }} />
            <Typography variant="subtitle1">Balance</Typography>
          </Box>
          <Typography variant="h6" color="primary.main">
            ${calculateBalance()}
          </Typography>
        </Paper>
      </Box>
    )
  }

  const generatePrompt = () => {
    const transactionsSummary = transactions.map(t => (
      `${t.date}: ${t.type === 'income' ? '+' : '-'}${t.amount} ${t.currency} (${t.categoryModel?.name}) - ${t.description} [by ${t.createdBy?.username}]`
    )).join('\n');

    const prompt = `Please based on my transactions as follows, write me a family expense&income diary.

Date Range: ${startDate} to ${endDate}
Exchange Rate: 1 USD = ${exchangeRate.toFixed(2)} CNY

Total Income: $${calculateTotal('income')}
Total Expenses: $${calculateTotal('expense')}
Balance: $${calculateBalance()}

Detailed Records:
${transactionsSummary}
`;

    return prompt;
  };

  const handleCopyPrompt = async () => {
    try {
      await navigator.clipboard.writeText(generatePrompt());
      setOpenSnackbar(true);
    } catch (err) {
      console.error('Failed to copy prompt:', err);
    }
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="h6">Transactions Summary (USD)</Typography>
        <MuiTooltip title="AI Analysis with ChatGPT">
          <IconButton onClick={handleCopyPrompt} size="small">
            <PsychologyIcon />
          </IconButton>
        </MuiTooltip>
      </Box>
      <Typography variant="caption" color="text.secondary" sx={{ mb: 2, display: 'block' }}>
        Exchange rate: 1 USD = {exchangeRate.toFixed(2)} CNY
      </Typography>

      <Box sx={{ mt: 2 }}>
        {isMobile ? (
          <Box sx={{ 
            '& .swiper': { 
              height: '300px'
            },
            '& .swiper-slide': {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }
          }}>
            <Swiper
              modules={[Pagination]}
              slidesPerView={1}
              pagination={{ clickable: true }}
            >
              <SwiperSlide>
                <AccountInfo />
              </SwiperSlide>
              {charts.map((chart, index) => (
                <SwiperSlide key={index}>
                  <PieChartBox data={chart.data} title={chart.title} />
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>
        ) : (
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'row',
            flexWrap: 'wrap', 
            justifyContent: 'space-around', 
            alignItems: 'center',
            mb: 2
          }}>
            <Box key={"account-info"} sx={{ width: '25%', minWidth: 150 }}>
              <AccountInfo />
            </Box>
            {charts.map((chart, index) => (
              <Box key={index} sx={{ width: '25%', minWidth: 150 }}>
                <PieChartBox data={chart.data} title={chart.title} />
              </Box>
            ))}
          </Box>
        )}
      </Box>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
        message="Prompt copied to clipboard!"
        action={
          <Button
            color="primary"
            size="small"
            onClick={openChatGPT}  // 使用新的 openChatGPT 函数
            startIcon={<OpenInNewIcon />}
            sx={{ 
              color: 'white',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)'
              }
            }}
          >
            Open ChatGPT
          </Button>
        }
      />
    </Box>
  );
}

export default SummarySection;