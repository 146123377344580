import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Container,
  Typography,
  Box,
  Paper,
  TextField,
  Button,
  Alert,
  Divider,
  Tab,
  Tabs,
  IconButton,
  Switch,
  FormControlLabel,
  Input,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import DeleteIcon from '@mui/icons-material/Delete';
import { createArticle, uploadImage } from '../../services/api';
import { MarkdownEditor } from './components/MarkdownEditor';

export function BlogCreate() {
  const navigate = useNavigate();
  const location = useLocation();
  const [formData, setFormData] = useState({
    title: '',
    summary: '',
    content: '',
    coverImage: ''
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const [showSplitView, setShowSplitView] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [showLeaveDialog, setShowLeaveDialog] = useState(false);
  const [navigationPath, setNavigationPath] = useState('');

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleBack = () => {
    setShowLeaveDialog(true);
    setNavigationPath('/blog');
  };

  const handleNavigate = (path) => {
    setShowLeaveDialog(true);
    setNavigationPath(path);
  };

  const handleConfirmNavigation = () => {
    setShowLeaveDialog(false);
    navigate(navigationPath);
  };

  const handleCancelNavigation = () => {
    setShowLeaveDialog(false);
    setNavigationPath('');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Show preview immediately
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(file);

    try {
      setUploadLoading(true);
      setError(null);
      const imageUrl = await uploadImage(file);
      setFormData(prev => ({
        ...prev,
        coverImage: imageUrl
      }));
      setPreviewImage(null); // Clear preview after successful upload
    } catch (err) {
      setError('Failed to upload image');
      setPreviewImage(null); // Clear preview on error
    } finally {
      setUploadLoading(false);
    }
  };

  const handleRemoveImage = () => {
    setFormData(prev => ({
      ...prev,
      coverImage: ''
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      await createArticle(formData);
      navigate('/blog');
    } catch (err) {
      setError(err.message || 'Failed to create article');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <IconButton onClick={handleBack} sx={{ mb: 2 }}>
          <ArrowBackIcon />
        </IconButton>

        <Paper elevation={3} sx={{ p: { xs: 2, sm: 4 } }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Create New Article
          </Typography>

          {error && (
            <Alert severity="error" sx={{ mb: 3 }}>
              {error}
            </Alert>
          )}

          <Box component="form" onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              required
              sx={{ mb: 3 }}
            />

            <TextField
              fullWidth
              label="Summary"
              name="summary"
              value={formData.summary}
              onChange={handleChange}
              required
              multiline
              rows={2}
              sx={{ mb: 3 }}
            />

            {/* Image Upload Section */}
            <Box sx={{ mb: 3 }}>
              <Input
                accept="image/*"
                id="cover-image-upload"
                type="file"
                sx={{ display: 'none' }}
                onChange={handleImageUpload}
              />
              <label htmlFor="cover-image-upload">
                <Button
                  variant="outlined"
                  component="span"
                  startIcon={<PhotoCamera />}
                  disabled={uploadLoading}
                >
                  {uploadLoading ? 'Uploading...' : 'Upload Cover Image'}
                </Button>
              </label>

              {(previewImage || formData.coverImage) && (
                <Box sx={{ mt: 2, position: 'relative' }}>
                  <Box
                    component="img"
                    src={previewImage || formData.coverImage}
                    alt="Cover preview"
                    sx={{
                      width: '100%',
                      maxWidth: '600px',
                      height: 'auto',
                      maxHeight: '200px',
                      objectFit: 'cover',
                      borderRadius: 1,
                      filter: previewImage ? 'brightness(0.8)' : 'none'
                    }}
                  />
                  {previewImage && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        color: 'white',
                        textAlign: 'center',
                        width: '100%',
                        typography: 'body2',
                        textShadow: '0 0 4px rgba(0,0,0,0.5)'
                      }}
                    >
                      Uploading...
                    </Box>
                  )}
                  {formData.coverImage && (
                    <IconButton
                      onClick={handleRemoveImage}
                      sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        bgcolor: 'background.paper',
                        '&:hover': {
                          bgcolor: 'background.paper',
                          opacity: 0.9
                        }
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Box>
              )}

              {uploadLoading && (
                <Box sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
                  <CircularProgress size={16} sx={{ mr: 1 }} />
                  <Typography variant="body2" color="text.secondary">
                    Uploading image...
                  </Typography>
                </Box>
              )}
            </Box>

            <Divider sx={{ mb: 3 }} />

            {/* Editor Section */}
            <Box sx={{ mb: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Tabs
                  value={tab}
                  onChange={(e, newValue) => setTab(newValue)}
                >
                  <Tab label="Write" />
                </Tabs>
                <FormControlLabel
                  control={
                    <Switch
                      checked={showSplitView}
                      onChange={(e) => setShowSplitView(e.target.checked)}
                    />
                  }
                  label="Split View"
                />
              </Box>
            </Box>

            <Box sx={{ 
              width: '100%',
              maxWidth: '100%',
              mb: 3,
              '& > *': {
                maxWidth: '100%'
              }
            }}>
              <MarkdownEditor
                value={formData.content}
                onChange={handleChange}
                disabled={loading}
                showSplitView={showSplitView}
              />
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
              <Button
                variant="outlined"
                onClick={() => handleNavigate('/blog')}
                disabled={loading}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                disabled={loading}
              >
                Publish Article
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
      <Dialog
        open={showLeaveDialog}
        onClose={handleCancelNavigation}
      >
        <DialogTitle>Leave this page?</DialogTitle>
        <DialogContent>
          Your changes will be lost if you leave this page.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelNavigation}>Stay</Button>
          <Button onClick={handleConfirmNavigation} color="primary">
            Leave
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}