import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Paper,
  Button,
  Snackbar,
  Alert,
  List,
  ListItem,
  Divider,
  Box,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { 
  getLedgerDetails, 
  getLedgerCategories, 
  addLedgerCategory, 
  deleteLedgerCategory,
  updateLedgerCategory,
  getLedgerRecurringTemplates,
  getExchangeRate
} from '../services/api';
import ManageSection from '../components/LedgerDetail/ManageSection';
import AddCategoryModal from '../components/modals/AddCategoryModal';
import EditCategoryModal from '../components/modals/EditCategoryModal';
import DeleteCategoryConfirmationModal from '../components/modals/DeleteCategoryConfirmationModal';
import AddCollaboratorModal from '../components/modals/AddCollaboratorModal';

function LedgerManage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [ledger, setLedger] = useState(null);
  const [categories, setCategories] = useState([]);
  const [isCollaboratorModalOpen, setIsCollaboratorModalOpen] = useState(false);
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [newCategory, setNewCategory] = useState({ name: '', type: 'expense' });
  const [deletingCategoryId, setDeletingCategoryId] = useState(null);
  const [isCategoryDeleteConfirmOpen, setIsCategoryDeleteConfirmOpen] = useState(false);
  const [newCollaborator, setNewCollaborator] = useState('');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const [recurringTemplates, setRecurringTemplates] = useState([]);
  const [exchangeRates, setExchangeRates] = useState({
    CNY: 0,
    USD: 1
  });
  const [editingCategory, setEditingCategory] = useState(null);
  const [isEditCategoryModalOpen, setIsEditCategoryModalOpen] = useState(false);

  useEffect(() => {
    fetchLedgerDetails();
    fetchCategories();
    fetchRecurringTemplates();
    fetchExchangeRates();
  }, [id]);

  const fetchLedgerDetails = async () => {
    try {
      const data = await getLedgerDetails(id);
      setLedger(data);
    } catch (err) {
      console.error('Failed to fetch ledger details:', err);
    }
  };

  const fetchCategories = async () => {
    try {
      const data = await getLedgerCategories(id);
      setCategories(data);
    } catch (err) {
      console.error('Failed to fetch categories:', err);
    }
  };

  const fetchRecurringTemplates = async () => {
    try {
      const response = await getLedgerRecurringTemplates(id);
      setRecurringTemplates(response);
      
    } catch (err) {
      console.error('Failed to fetch recurring templates:', err);
      setSnackbar({
        open: true,
        message: 'Failed to fetch recurring templates',
        severity: 'error'
      });
    }
  };

  const fetchExchangeRates = async () => {
    try {
      const cnyRate = await getExchangeRate('USD', 'CNY');
      setExchangeRates({
        CNY: 1/cnyRate,
        USD: 1
      });
    } catch (err) {
      console.error('Failed to fetch exchange rates:', err);
    }
  };

  const handleAddCategory = async (newCategory) => {
    try {
      const response = await addLedgerCategory(id, newCategory);
      setCategories(prevCategories => [...prevCategories, response]);
      setIsCategoryModalOpen(false);
      setNewCategory({ name: '', type: 'expense' });
      setSnackbar({
        open: true,
        message: 'Category added successfully',
        severity: 'success'
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.response?.data?.error || 'Failed to add category',
        severity: 'error'
      });
    }
  };

  const handleDeleteCategory = async () => {
    if (!deletingCategoryId) return;

    try {
      await deleteLedgerCategory(id, deletingCategoryId);
      setCategories(prevCategories => 
        prevCategories.filter(category => category._id !== deletingCategoryId)
      );
      setIsCategoryDeleteConfirmOpen(false);
      setDeletingCategoryId(null);
      setSnackbar({
        open: true,
        message: 'Category deleted successfully',
        severity: 'success'
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.response?.data?.error || 'Failed to delete category',
        severity: 'error'
      });
    }
  };

  const handleDeleteCategoryClick = (categoryId) => {
    setDeletingCategoryId(categoryId);
    setIsCategoryDeleteConfirmOpen(true);
  };

  const handleEditCategory = async (updatedCategory) => {
    try {
      const response = await updateLedgerCategory(id, editingCategory._id, updatedCategory);
      setCategories(prevCategories =>
        prevCategories.map(cat =>
          cat._id === editingCategory._id ? response : cat
        )
      );
      setIsEditCategoryModalOpen(false);
      setEditingCategory(null);
      setSnackbar({
        open: true,
        message: 'Category updated successfully',
        severity: 'success'
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.response?.data?.error || 'Failed to update category',
        severity: 'error'
      });
    }
  };

  const handleEditCategoryClick = (category) => {
    setEditingCategory(category);
    setIsEditCategoryModalOpen(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const calculateRecurringSummary = () => {
    if (!recurringTemplates || recurringTemplates.length === 0) return null;

    const summary = {
      total: recurringTemplates.length,
      totalExpense: 0,
      monthlyExpenseUSD: 0
    };

    recurringTemplates.forEach(template => {
      if (template.type === 'expense') {
        summary.totalExpense++;
        const amountInUSD = template.currency === 'USD' 
          ? template.amount 
          : template.amount * exchangeRates[template.currency];
        summary.monthlyExpenseUSD += amountInUSD;
      }
    });

    return summary;
  };

  const RecurringTransactionsSection = () => {
    const summary = calculateRecurringSummary();

    return (
      <Paper elevation={2} sx={{ p: 3, mb: 3, mt: 1 }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          mb: 3 
        }}>
          <Typography variant="h6" component="h2">
            Recurring Transactions
          </Typography>
          {summary && (
            <Typography variant="subtitle2" color="text.secondary">
              Total: {summary.total} ({summary.totalExpense} expenses)
            </Typography>
          )}
        </Box>

        {summary && summary.totalExpense > 0 && (
          <Box sx={{ 
            mb: 3, 
            p: 2, 
            bgcolor: 'background.default', 
            borderRadius: 1
          }}>
            <Typography variant="subtitle2" color="error.main" sx={{ fontWeight: 'bold' }}>
              Monthly Expenses: ${summary.monthlyExpenseUSD.toFixed(2)} USD
            </Typography>
          </Box>
        )}

        {recurringTemplates && recurringTemplates.length > 0 ? (
          <List sx={{ 
            bgcolor: 'background.paper',
            borderRadius: 1,
            border: 1,
            borderColor: 'divider'
          }}>
            {recurringTemplates
              .filter(template => template.type === 'expense')
              .map((template, index) => (
              <React.Fragment key={template._id}>
                {index > 0 && <Divider />}
                <ListItem 
                  sx={{ 
                    flexDirection: 'column', 
                    alignItems: 'stretch',
                    '&:hover': {
                      bgcolor: 'action.hover'
                    }
                  }}
                >
                  <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center',
                    mb: 1 
                  }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                      {template.description || 'Untitled Transaction'}
                    </Typography>
                    <Box>
                      <Typography 
                        variant="subtitle1" 
                        color="error.main"
                        sx={{ fontWeight: 'bold' }}
                      >
                        -{template.amount.toFixed(2)} {template.currency}
                      </Typography>
                      {template.currency !== 'USD' && (
                        <Typography 
                          variant="caption" 
                          color="text.secondary"
                          sx={{ display: 'block', textAlign: 'right' }}
                        >
                          ≈ ${(template.amount * exchangeRates[template.currency]).toFixed(2)} USD
                        </Typography>
                      )}
                    </Box>
                  </Box>

                  <Box sx={{ 
                    display: 'flex', 
                    flexWrap: 'wrap',
                    gap: 2,
                    color: 'text.secondary',
                    mb: 1
                  }}>
                    <Typography variant="body2">
                      Category: {template.category}
                    </Typography>
                    <Typography variant="body2">
                      Repeats: {template.recurringType}
                    </Typography>
                  </Box>

                  <Box sx={{ 
                    display: 'flex', 
                    flexWrap: 'wrap',
                    gap: 2,
                    color: 'text.secondary'
                  }}>
                    <Typography variant="body2">
                      Starts: {formatDate(template.recurringStartDate)}
                    </Typography>
                    <Typography variant="body2">
                      {template.recurringEndDate ? 
                        `Ends: ${formatDate(template.recurringEndDate)}` : 
                        'No end date'}
                    </Typography>
                    <Typography variant="body2">
                      Created by: {template.createdBy?.username}
                    </Typography>
                  </Box>
                </ListItem>
              </React.Fragment>
            ))}
          </List>
        ) : (
          <Typography 
            color="text.secondary" 
            sx={{ 
              textAlign: 'center',
              py: 3,
              bgcolor: 'background.paper',
              borderRadius: 1,
              border: 1,
              borderColor: 'divider'
            }}
          >
            No recurring expenses found
          </Typography>
        )}
      </Paper>
    );
  };

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate(`/ledger/${id}`)}
        sx={{ mb: 2 }}
      >
        Back to Ledger
      </Button>
      <Typography variant="h4" component="h1" sx={{ mb: 4 }}>
        Manage {ledger?.name}
      </Typography>
      
      <ManageSection
        ledger={ledger}
        categories={categories}
        onAddCategory={() => setIsCategoryModalOpen(true)}
        onEditCategory={handleEditCategoryClick}
        onDeleteCategory={handleDeleteCategoryClick}
        recurringTemplates={recurringTemplates}
        exchangeRates={exchangeRates}
      />

      <RecurringTransactionsSection />

      <AddCategoryModal
        isOpen={isCategoryModalOpen}
        onClose={() => setIsCategoryModalOpen(false)}
        onConfirm={handleAddCategory}
      />

      <EditCategoryModal
        isOpen={isEditCategoryModalOpen}
        onClose={() => {
          setIsEditCategoryModalOpen(false);
          setEditingCategory(null);
        }}
        onConfirm={handleEditCategory}
        category={editingCategory}
      />

      <DeleteCategoryConfirmationModal
        isOpen={isCategoryDeleteConfirmOpen}
        onClose={() => setIsCategoryDeleteConfirmOpen(false)}
        onConfirm={handleDeleteCategory}
      />

      <AddCollaboratorModal
        isOpen={isCollaboratorModalOpen}
        onClose={() => setIsCollaboratorModalOpen(false)}
        newCollaborator={newCollaborator}
        setNewCollaborator={setNewCollaborator}
        handleAddCollaborator={async () => {
          // Implement handleAddCollaborator logic here
        }}
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default LedgerManage;