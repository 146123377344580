import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { 
  Container, 
  Typography, 
  Box, 
  CircularProgress, 
  Button,
  Divider,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import { getArticleById, deleteArticle } from '../../services/api';
import { MarkdownComponents } from './components/MarkdownComponents';
import { useAuth } from '../../contexts/AuthContext';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

export function BlogDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);

  useEffect(() => {
    if (article?.title) {
      document.title = `${article.title}`;
    }
    return () => {
      document.title = 'Shared Tracker';
    };
  }, [article]);

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        setLoading(true);
        const data = await getArticleById(id);
        setArticle(data);
      } catch (err) {
        setError('Failed to fetch article details');
        console.error('Error fetching article:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchArticle();
  }, [id]);

  const handleDelete = async () => {
    try {
      await deleteArticle(id);
      setDeleteDialogOpen(false);
      setShowSuccessToast(true);
      setTimeout(() => {
        navigate('/blog');
      }, 800);
    } catch (err) {
      setError('Failed to delete article');
      console.error('Error deleting article:', err);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg">
        <Typography color="error" align="center" sx={{ mt: 4 }}>
          {error}
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      {article && (
        <Helmet>
          <title>{article.title}</title>
          <meta name="description" content={article.summary} />
          <meta property="og:title" content={article.title} />
          <meta property="og:description" content={article.summary} />
          {article.coverImage && (
            <meta property="og:image" content={article.coverImage} />
          )}
          <meta property="og:type" content="article" />
          <meta property="article:published_time" content={article.createdAt} />
          <meta property="article:author" content={article.author?.nickname || article.author?.username} />
          <link rel="canonical" href={window.location.href} />
          {/* Add structured data for Google */}
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "BlogPosting",
              "headline": article.title,
              "description": article.summary,
              "image": article.coverImage,
              "author": {
                "@type": "Person",
                "name": article.author?.nickname || article.author?.username
              },
              "datePublished": article.createdAt,
              "dateModified": article.updatedAt,
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": window.location.href
              }
            })}
          </script>
        </Helmet>
      )}
      <Box sx={{ my: 4 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate('/blog')}
          >
            Back to Blog
          </Button>
          
          {user && article && user.id === article.authorId && (
            <Box>
              <Button
                startIcon={<EditIcon />}
                onClick={() => navigate(`/blog/edit/${id}`)}
                sx={{ mr: 1 }}
              >
                Edit
              </Button>
              <Button
                startIcon={<DeleteIcon />}
                color="error"
                onClick={() => setDeleteDialogOpen(true)}
              >
                Delete
              </Button>
            </Box>
          )}
        </Box>

        {article && (
          <Paper 
            elevation={3} 
            sx={{ 
              p: { xs: 2, sm: 4 },
              bgcolor: 'background.paper',
            }}
          >
            {/* Cover Image Section with Title Overlay */}
            <Box 
              sx={{ 
                position: 'relative',
                width: '100%',
                mb: 3,
                borderRadius: 1,
                overflow: 'hidden',
                height: article.coverImage ? '400px' : 'auto',
                bgcolor: 'grey.100'
              }}
            >
              {article.coverImage ? (
                <>
                  <Box
                    component="img"
                    src={article.coverImage}
                    alt={article.title}
                    sx={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      display: 'block'
                    }}
                  />
                  {/* Gradient Overlay */}
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      background: 'linear-gradient(to bottom, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.8) 100%)',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-end',
                      p: { xs: 3, sm: 4 }
                    }}
                  >
                    <Typography 
                      variant="h3" 
                      component="h1" 
                      gutterBottom
                      sx={{
                        color: 'white',
                        fontSize: { xs: '1.75rem', sm: '2.5rem', md: '3rem' },
                        textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
                        fontWeight: 'bold'
                      }}
                    >
                      {article.title}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 2, color: 'rgba(255,255,255,0.9)' }}>
                      <Typography 
                        variant="body2"
                        sx={{ 
                          textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
                          fontSize: { xs: '0.875rem', sm: '1rem' }
                        }}
                      >
                        By {article.author?.nickname || article.author?.username || 'Unknown'}
                      </Typography>
                      <Typography 
                        variant="body2"
                        sx={{ 
                          textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
                          fontSize: { xs: '0.875rem', sm: '1rem' }
                        }}
                      >
                        {new Date(article.createdAt).toLocaleDateString()}
                      </Typography>
                      <Typography 
                        variant="body2"
                        sx={{ 
                          textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
                          fontSize: { xs: '0.875rem', sm: '1rem' }
                        }}
                      >
                        {article.pv || 0} views
                      </Typography>
                    </Box>
                  </Box>
                </>
              ) : (
                // Fallback when no cover image
                <Box sx={{ p: 4 }}>
                  <Typography 
                    variant="h3" 
                    component="h1" 
                    gutterBottom
                    sx={{
                      fontSize: { xs: '1.75rem', sm: '2.5rem', md: '3rem' },
                      fontWeight: 'bold'
                    }}
                  >
                    {article.title}
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 2, color: 'text.secondary' }}>
                    <Typography 
                      variant="body2"
                      sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}
                    >
                      By {article.author?.nickname || article.author?.username || 'Unknown'}
                    </Typography>
                    <Typography 
                      variant="body2"
                      sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}
                    >
                      {new Date(article.createdAt).toLocaleDateString()}
                    </Typography>
                    <Typography 
                      variant="body2"
                      sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}
                    >
                      {article.pv || 0} views
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>

            {/* Content Section with Markdown Rendering */}
            <Box sx={{ mt: 4, '& > *:first-child': { mt: 0 }, '& > *:last-child': { mb: 0 } }}>
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw, rehypeSanitize]}
                components={MarkdownComponents}
              >
                {article.content}
              </ReactMarkdown>
            </Box>
          </Paper>
        )}
      </Box>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">
          Delete Article
        </DialogTitle>
        <DialogContent>
          <Typography id="delete-dialog-description">
            Are you sure you want to delete this article? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleDelete} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={showSuccessToast}
        autoHideDuration={3000}
        onClose={() => setShowSuccessToast(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setShowSuccessToast(false)} 
          severity="success" 
          sx={{ width: '100%' }}
        >
          Article successfully deleted
        </Alert>
      </Snackbar>
    </Container>
  );
}