import React from 'react';
import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import { Line } from 'react-chartjs-2';
import { format } from 'date-fns';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import moment from 'moment';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler, ChartDataLabels);

// Add this helper function
function ensureValidColor(color, fallback = '#ffffff') {
  return /^#[0-9A-F]{6}$/i.test(color) ? color : fallback;
}

function IncomeExpenseTrend({ chartData }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // 使用更柔和的颜色
  const incomeColor = '#4CAF50';  // 柔和的绿色
  const expenseColor = '#FF5722';  // 柔和的红橙色
  const backgroundColor = ensureValidColor(theme.palette.background.default);

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: !isMobile,
          text: 'Amount (USD)',
          font: {
            size: 14,
            weight: 'bold',
          },
        },
        ticks: {
          callback: function(value) {
            return isMobile ? '$' + value : '$' + value.toFixed(2);
          },
          maxTicksLimit: isMobile ? 5 : 8,
          font: {
            size: isMobile ? 10 : 12,
          },
        },
        grid: {
          color: theme.palette.divider,
        },
      },
      x: {
        title: {
          display: !isMobile,
          text: 'Date',
          font: {
            size: 14,
            weight: 'bold',
          },
        },
        ticks: {
          display: !isMobile,
          maxRotation: 0,
          autoSkip: true,
          maxTicksLimit: 7,
          font: {
            size: isMobile ? 10 : 12,
          },
        },
        grid: {
          display: false,
        },
      },
    },
    elements: {
      line: {
        tension: 0.3,
        borderWidth: isMobile ? 2 : 3,
      },
      point: {
        radius: isMobile ? 2 : 4,
        hoverRadius: isMobile ? 4 : 6,
      },
    },
    plugins: {
      legend: {
        position: isMobile ? 'bottom' : 'top',
        labels: {
          boxWidth: isMobile ? 10 : 40,
          padding: isMobile ? 10 : 20,
          font: {
            size: isMobile ? 10 : 12,
          },
        },
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        callbacks: {
          title: function(tooltipItems) {
            return moment(tooltipItems[0].label).format('MMM D, YYYY');
          },
          label: function(context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
            }
            return label;
          }
        },
        backgroundColor: theme.palette.background.paper,
        titleColor: theme.palette.text.primary,
        bodyColor: theme.palette.text.secondary,
        borderColor: theme.palette.divider,
        borderWidth: 1,
      },
      datalabels: {
        display: false, // 这里关闭数据标签的显示
      },
    },
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false
    },
  };

  const enhancedChartData = {
    ...chartData,
    datasets: chartData.datasets.map((dataset, index) => ({
      ...dataset,
      borderColor: index === 0 ? incomeColor : expenseColor,
      backgroundColor: (context) => {
        const ctx = context.chart.ctx;
        const gradient = ctx.createLinearGradient(0, 0, 0, context.chart.height);
        if (index === 0) {
          // Income gradient
          gradient.addColorStop(0, `${incomeColor}CC`);  // 80% opacity
          gradient.addColorStop(0.5, `${incomeColor}80`);  // 50% opacity
          gradient.addColorStop(1, `${incomeColor}20`);  // 12% opacity
        } else {
          // Expense gradient
          gradient.addColorStop(0, `${expenseColor}CC`);  // 80% opacity
          gradient.addColorStop(0.5, `${expenseColor}80`);  // 50% opacity
          gradient.addColorStop(1, `${expenseColor}20`);  // 12% opacity
        }
        return gradient;
      },
      fill: true,
      pointBackgroundColor: index === 0 ? incomeColor : expenseColor,
      pointBorderColor: theme.palette.background.paper,
      pointBorderWidth: 2,
    })),
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', mb: 2 }}>
        Income and Expense Trend
      </Typography>
      <Box sx={{ height: isMobile ? 250 : 350 }}>
        <Line data={enhancedChartData} options={chartOptions} />
      </Box>
    </Box>
  );
}

export default IncomeExpenseTrend;