import React, { useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  Button, 
  Box, 
  IconButton, 
  Menu, 
  MenuItem, 
  useTheme, 
  useMediaQuery,
  Avatar,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useAuth } from '../contexts/AuthContext';
import { useColorMode } from '../contexts/ColorModeContext';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Home from '@mui/icons-material/Home';
import Article from '@mui/icons-material/Article';
import MenuBook from '@mui/icons-material/MenuBook';

function Header() {
  const { isLoggedIn, user, logout: authLogout } = useAuth();
  const { mode, toggleColorMode } = useColorMode();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Function to generate avatar content
  const getAvatarContent = (username) => {
    if (!username) return '';
    return username.charAt(0).toUpperCase();
  };

  // Function to generate avatar color
  const stringToColor = (string) => {
    if (!string) return theme.palette.primary.main;
    let hash = 0;
    for (let i = 0; i < string.length; i++) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xFF;
      color += ('00' + value.toString(16)).substr(-2);
    }
    return color;
  };

  // Function to render avatar with fallback
  const renderAvatar = (size = 32, fontSize = '1rem') => {
    const avatarProps = {
      sx: { 
        width: size, 
        height: size,
        fontSize: fontSize,
        ...(user?.avatarUrl ? {} : { bgcolor: stringToColor(user?.username) })
      }
    };

    if (user?.avatarUrl) {
      return <Avatar {...avatarProps} src={user.avatarUrl} alt={user.username} />;
    }

    return (
      <Avatar {...avatarProps}>
        {getAvatarContent(user?.username)}
      </Avatar>
    );
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenu = (event) => {
    setMobileMenuAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMobileMenuAnchorEl(null);
  };

  const handleLogout = () => {
    authLogout();
    navigate('/');
    handleClose();
  };

  const isActive = (path) => location.pathname === path;

  const renderMenuItems = () => [
    <Button 
      key="home"
      color="inherit" 
      component={RouterLink} 
      to="/"
      sx={{ 
        fontWeight: isActive('/') ? 'bold' : 'normal',
        borderBottom: isActive('/') ? '2px solid white' : 'none'
      }}
    >
      Home
    </Button>,
    isLoggedIn && (
      <Button 
        key="trackers"
        color="inherit" 
        component={RouterLink} 
        to="/ledgers"
        sx={{ 
          fontWeight: isActive('/ledgers') ? 'bold' : 'normal',
          borderBottom: isActive('/ledgers') ? '2px solid white' : 'none'
        }}
      >
        My Trackers
      </Button>
    )
  ].filter(Boolean);

  const renderUserMenu = () => (
    <Menu
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuItem>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {renderAvatar(24, '0.875rem')}
          <Typography variant="body2" color="textSecondary">
            {user?.username}
          </Typography>
        </Box>
      </MenuItem>
      <MenuItem component={RouterLink} to="/profile" onClick={handleClose}>Profile</MenuItem>
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );

  const renderMobileMenuItems = () => {
    const commonItems = [
      {
        text: mode === 'dark' ? "Light Mode" : "Dark Mode",
        icon: mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />,
        onClick: toggleColorMode
      },
      {
        text: 'Home',
        icon: <Home />,
        onClick: () => navigate('/')
      },
      {
        text: 'Blog',
        icon: <Article />,
        onClick: () => navigate('/blog')
      },
      {
        text: 'Novel',
        icon: <MenuBook />,
        onClick: () => navigate('/novel')
      }
    ];

    if (isLoggedIn) {
      return [
        ...commonItems,
        {
          text: 'My Trackers',
          icon: null,
          onClick: () => navigate('/ledgers')
        },
        {
          text: 'Profile',
          icon: null,
          onClick: () => navigate('/profile')
        },
        {
          text: 'Logout',
          icon: null,
          onClick: handleLogout
        }
      ];
    } else {
      return [
        ...commonItems,
        {
          text: 'Login/Register',
          icon: null,
          onClick: () => navigate('/auth')
        }
      ];
    }
  };

  const renderMobileMenu = () => (
    <Menu
      anchorEl={mobileMenuAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(mobileMenuAnchorEl)}
      onClose={handleClose}
      PaperProps={{
        sx: {
          mt: 1,
          width: '100%',
          maxWidth: '300px',
          borderRadius: 2,
          '& .MuiList-root': {
            py: 1
          }
        }
      }}
    >
      {renderMobileMenuItems().map((item, index) => (
        <MenuItem key={index} onClick={item.onClick}>
          {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
          <ListItemText primary={item.text} />
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <AppBar position="fixed" sx={{ backgroundColor: theme.palette.primary.main }}>
      <Toolbar>
        <Typography variant="h6" component={RouterLink} to="/" sx={{ flexGrow: 1, textDecoration: 'none', color: 'inherit', fontWeight: 'bold' }}>
          Shared Tracker
        </Typography>
        {isMobile ? (
          <>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleMobileMenu}
            >
              <MenuIcon />
            </IconButton>
            {renderMobileMenu()}
          </>
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              color="inherit"
              onClick={toggleColorMode}
              sx={{ ml: 1 }}
            >
              {mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
            {renderMenuItems()}
            {isLoggedIn ? (
              <>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                  sx={{ p: 0.5 }}
                >
                  {renderAvatar(32, '1rem')}
                </IconButton>
                {renderUserMenu()}
              </>
            ) : (
              <Button 
                color="inherit" 
                component={RouterLink} 
                to="/auth"
                sx={{ 
                  fontWeight: isActive('/auth') ? 'bold' : 'normal',
                  borderBottom: isActive('/auth') ? '2px solid white' : 'none'
                }}
              >
                Login/Register
              </Button>
            )}
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Header;