import React, { useState, useMemo } from 'react';
import { Box, TextField, CircularProgress, Typography, List, ListItem, ListItemText, IconButton, Divider, MenuItem, Paper, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, parseISO } from 'date-fns';
import { styled } from '@mui/material/styles';
import { formatInTimeZone } from 'date-fns-tz';
import RepeatIcon from '@mui/icons-material/Repeat';

const HighlightedText = styled('span')(({ theme }) => ({
  backgroundColor: theme.palette.warning.light,
  padding: '0 2px',
  borderRadius: '2px',
}));

// Add this helper function at the top of the file, outside the component
const getTimeZoneAbbr = () => {
  return new Intl.DateTimeFormat('en-US', {
    timeZone: 'America/Los_Angeles',
    timeZoneName: 'short'
  }).formatToParts(new Date()).find(part => part.type === 'timeZoneName').value;
};

function TransactionsSection({
  isMobile,
  setIsAddModalOpen,
  startDate,
  endDate,
  handleStartDateChange,
  handleEndDateChange,
  today,
  loading,
  transactions,
  handleEdit,
  handleDeleteClick,
  children,
  exchangeRate,
  convertToUSD,
  categories, // 添加 categories prop
}) {
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingTransaction, setEditingTransaction] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const categoryNames = useMemo(() => {
    const categoryCounts = transactions.reduce((acc, t) => {
      const categoryName = t.categoryModel ? t.categoryModel.name : 'Uncategorized';
      acc[categoryName] = (acc[categoryName] || 0) + 1;
      return acc;
    }, {});
    return Object.keys(categoryCounts).sort();
  }, [transactions]);

  const filteredTransactions = useMemo(() => {
    return transactions.filter(transaction => {
      const matchesCategory = selectedCategory === 'All' || 
        (transaction.categoryModel && transaction.categoryModel.name === selectedCategory);
      
      const matchesSearch = searchQuery === '' || 
        transaction.description.toLowerCase().includes(searchQuery.toLowerCase());
      
      return matchesCategory && matchesSearch;
    });
  }, [transactions, selectedCategory, searchQuery]);

  const groupTransactionsByDate = (transactions) => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const grouped = {};
    transactions.forEach(transaction => {
      const date = formatInTimeZone(parseISO(transaction.date), timeZone, 'yyyy-MM-dd');
      if (!grouped[date]) {
        grouped[date] = {
          transactions: [],
          totalIncome: 0,
          totalExpense: 0
        };
      }
      grouped[date].transactions.push(transaction);
      const amountInUSD = convertToUSD(transaction.amount, transaction.currency, exchangeRate);
      if (transaction.type === 'income') {
        grouped[date].totalIncome += amountInUSD;
      } else {
        grouped[date].totalExpense += amountInUSD;
      }
    });
    return grouped;
  };

  const highlightText = (text, query) => {
    if (!query.trim() || !text) return text;
    
    const escapedQuery = query.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(`(${escapedQuery})`, 'gi');
    
    const textStr = text.toString();
    const parts = textStr.split(regex);
    
    return parts.map((part, i) => 
      regex.test(part) ? <HighlightedText key={i}>{part}</HighlightedText> : part
    );
  };

  const renderTransactions = () => {
    const groupedTransactions = groupTransactionsByDate(filteredTransactions);
    return Object.entries(groupedTransactions).map(([date, dayData]) => (
      <Paper elevation={2} key={date} sx={{ mb: 2, overflow: 'hidden' }}>
        <Box sx={{ bgcolor: 'primary.main', color: 'primary.contrastText', py: 1, px: 2 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
            {formatInTimeZone(parseISO(date), 'America/Los_Angeles', 'EEEE, MMMM d, yyyy')}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="body2">
              Income: ${dayData.totalIncome.toFixed(2)}
            </Typography>
            <Typography variant="body2">
              Expense: ${dayData.totalExpense.toFixed(2)}
            </Typography>
          </Box>
        </Box>
        <List disablePadding>
          {dayData.transactions.map((transaction, index) => (
            <React.Fragment key={transaction._id}>
              <ListItem 
                sx={{
                  py: 1,
                  borderLeft: 3,
                  borderColor: transaction.type === 'income' ? 'success.main' : 'error.main',
                  '&:hover': {
                    bgcolor: 'action.hover',
                  },
                }}
              >
                <ListItemText
                  primary={
                    <Typography component="div">
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box display="flex" alignItems="center" gap={0.5}>
                          <Typography variant="body2" fontWeight="medium">
                            {highlightText(transaction.categoryModel?.name || 'Uncategorized', searchQuery)}
                          </Typography>
                          {transaction.isRecurring && (
                            <Tooltip title="Monthly recurring transaction">
                              <RepeatIcon 
                                fontSize="small" 
                                color="action"
                                sx={{ ml: 0.5 }}
                              />
                            </Tooltip>
                          )}
                        </Box>
                        <Typography 
                          variant="body2" 
                          fontWeight="bold"
                          color={transaction.type === 'income' ? 'success.main' : 'error.main'}
                        >
                          {transaction.type === 'income' ? '+' : '-'}
                          {highlightText(`${transaction.amount} ${transaction.currency}`, searchQuery)}
                          {transaction.currency !== 'USD' && (
                            <Typography variant="caption" component="span" color="text.secondary" sx={{ ml: 0.5 }}>
                              ({highlightText(`$${convertToUSD(transaction.amount, transaction.currency, exchangeRate).toFixed(2)}`, searchQuery)})
                            </Typography>
                          )}
                        </Typography>
                      </Box>
                    </Typography>
                  }
                  secondary={
                    <Typography component="div">
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="caption" color="text.secondary" component="span">
                          {transaction.description ? (
                            highlightText(transaction.description, searchQuery)
                          ) : '--'}
                          {transaction.isRecurring && ' (Recurring)'}
                          {' | by: '}
                          {transaction.createdBy?.username || '--'}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          {formatInTimeZone(parseISO(transaction.date), Intl.DateTimeFormat().resolvedOptions().timeZone, 'HH:mm')}
                        </Typography>
                      </Box>
                    </Typography>
                  }
                />
                <IconButton onClick={() => handleEdit(transaction)} size="small" sx={{ p: 0.5 }}>
                  <EditIcon fontSize="small" />
                </IconButton>
                <IconButton onClick={() => handleDeleteClick(transaction._id)} size="small" sx={{ p: 0.5 }}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </ListItem>
              {index < dayData.transactions.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </Paper>
    ));
  };



  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    setEditingTransaction(null);
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
        <Typography variant="h6" gutterBottom sx={{ mb: 1 }}>
          Transactions
        </Typography>
        <Box sx={{ mb: 1 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, mb: 1 }}>
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={handleStartDateChange}
                renderInput={(params) => <TextField {...params} size="small" fullWidth />}
                maxDate={today}
              />
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={handleEndDateChange}
                renderInput={(params) => <TextField {...params} size="small" fullWidth />}
                maxDate={today}
              />
            </Box>
          </LocalizationProvider>
          
          <TextField
            select
            label="Category"
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            size="small"
            fullWidth
            sx={{ mb: 1 }}
          >
            <MenuItem value="All">
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                <Typography>All Categories</Typography>
              </Box>
            </MenuItem>
            {categoryNames.map((categoryName) => (
              <MenuItem key={categoryName} value={categoryName}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                  <Typography>{categoryName}</Typography>
                </Box>
              </MenuItem>
            ))}
          </TextField>

          <TextField
            fullWidth
            size="small"
            label="Search transactions"
            placeholder="Search by description, category, or amount"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Box>
        {children}
      </Paper>

      {loading ? (
        <Box display="flex" justifyContent="center" my={2}>
          <CircularProgress size={24} />
        </Box>
      ) : filteredTransactions.length > 0 ? (
        <Box>
          <Typography variant="caption" color="textSecondary" sx={{ mb: 1, display: 'block' }}>
            Showing {filteredTransactions.length} transaction{filteredTransactions.length !== 1 ? 's' : ''} 
            {selectedCategory !== 'All' ? ` in ${selectedCategory} ` : ' '} 
            from {formatInTimeZone(startDate, Intl.DateTimeFormat().resolvedOptions().timeZone, 'MMM d, yyyy')} to {formatInTimeZone(endDate, Intl.DateTimeFormat().resolvedOptions().timeZone, 'MMM d, yyyy')} ({getTimeZoneAbbr()})
          </Typography>
          {renderTransactions()}
        </Box>
      ) : (
        <Typography variant="body2" color="textSecondary" align="center" sx={{ mt: 2 }}>
          No transactions found in the selected date range and category.
        </Typography>
      )}
    </Box>
  );
}

export default TransactionsSection;
