import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Container, 
  Paper, 
  Typography, 
  TextField, 
  Button, 
  Box,
  Tab,
  Tabs,
  Alert,
  IconButton,
  InputAdornment
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { register, login } from '../services/api';
import { useAuth } from '../contexts/AuthContext';

function Auth() {
  const { login: authLogin } = useAuth();
  const [isLogin, setIsLogin] = useState(true);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  const validatePassword = (password) => {
    const errors = [];
    
    if (password.length < 8) {
      errors.push("at least 8 characters");
    }
    if (!/[A-Z]/.test(password)) {
      errors.push("one uppercase letter");
    }
    if (!/[a-z]/.test(password)) {
      errors.push("one lowercase letter");
    }
    if (!/[0-9]/.test(password)) {
      errors.push("one number");
    }
    if (/\s/.test(password)) {
      errors.push("no spaces allowed");
    }
    
    return errors;
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      authLogin(token, null);
      navigate('/ledgers');
    }
  }, [authLogin, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    
    if (!username.trim() || !password.trim()) {
      setError('Please fill in all fields');
      return;
    }

    if (!isLogin) {
      if (username.length < 6) {
        setError('Username must be at least 6 characters long');
        return;
      }
      
      const passwordErrors = validatePassword(password);
      if (passwordErrors.length > 0) {
        setError(`Password requirements not met: ${passwordErrors.join(', ')}`);
        return;
      }

      if (password !== confirmPassword) {
        setError('Passwords do not match');
        return;
      }
    }

    try {
      if (isLogin) {
        const data = await login(username, password);
        setSuccess('Login successful!');
        authLogin(data.token, data.user);
        navigate('/ledgers');
      } else {
        const data = await register(username, password);
        setSuccess('Registration successful!');
        authLogin(data.token, data.user);
        navigate('/ledgers');
      }
    } catch (err) {
      setError(err.message || 'An error occurred. Please try again.');
    }
  };

  const getPasswordHelperText = () => {
    if (!isLogin) {
      if (password.length === 0) {
        return "Password must contain: at least 8 characters, one uppercase letter, one lowercase letter, one number, and no spaces";
      }
      const errors = validatePassword(password);
      if (errors.length > 0) {
        return `Missing requirements: ${errors.join(', ')}`;
      }
      return "Password meets all requirements";
    }
    return "";
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper 
        elevation={3} 
        sx={{ 
          mt: 8, 
          p: 4, 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          borderRadius: 2
        }}
      >
        <Typography component="h1" variant="h4" sx={{ mb: 3, fontWeight: 'bold' }}>
          Welcome
        </Typography>
        
        <Tabs
          value={isLogin ? 0 : 1}
          onChange={(_, newValue) => setIsLogin(!newValue)}
          sx={{ mb: 3, width: '100%' }}
        >
          <Tab label="Login" sx={{ width: '50%' }} />
          <Tab label="Register" sx={{ width: '50%' }} />
        </Tabs>

        {error && <Alert severity="error" sx={{ mt: 1, mb: 2, width: '100%' }}>{error}</Alert>}
        {success && <Alert severity="success" sx={{ mt: 1, mb: 2, width: '100%' }}>{success}</Alert>}
        
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            sx={{ mb: 2 }}
            helperText={!isLogin ? "Username must be at least 6 characters long" : ""}
            error={!isLogin && username.length > 0 && username.length < 6}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            id="password"
            autoComplete={isLogin ? 'current-password' : 'new-password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            helperText={getPasswordHelperText()}
            error={!isLogin && password.length > 0 && validatePassword(password).length > 0}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          
          {!isLogin && (
            <TextField
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label="Confirm Password"
              type={showConfirmPassword ? 'text' : 'password'}
              id="confirmPassword"
              autoComplete="new-password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              error={confirmPassword.length > 0 && password !== confirmPassword}
              helperText={confirmPassword.length > 0 && password !== confirmPassword ? "Passwords do not match" : ""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ 
              mt: 4, 
              mb: 2, 
              py: 1.5,
              borderRadius: 2,
              fontSize: '1.1rem'
            }}
          >
            {isLogin ? 'Login' : 'Register'}
          </Button>
        </Box>
      </Paper>
    </Container>
  );
}

export default Auth;