import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Container, Typography, Button, Box, Paper, IconButton, useTheme, useMediaQuery } from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CasinoIcon from '@mui/icons-material/Casino';
import BookIcon from '@mui/icons-material/Book';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { useColorMode } from '../contexts/ColorModeContext';

function Home() {
  const theme = useTheme();
  const { toggleColorMode, mode } = useColorMode();
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    window.scrollTo(0, 0);
    navigate(path);
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        background: theme.palette.mode === 'dark' 
          ? `linear-gradient(135deg, ${theme.palette.background.default} 0%, ${theme.palette.background.paper} 100%)`
          : 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
        pt: 8,
        pb: 6,
        transition: theme.transitions.create(['background'], {
          duration: theme.transitions.duration.standard,
        }),
      }}
    >
      {/* Theme Toggle Button */}
      <Box sx={{ position: 'fixed', top: 16, right: 16 }}>
        <IconButton onClick={toggleColorMode} color="inherit">
          {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton>
      </Box>

      <Container maxWidth="md">
        <Typography variant="h1" sx={{ 
          position: 'absolute',
          left: '-9999px',
          width: '1px',
          height: '1px',
          overflow: 'hidden'
        }}>
          Shared Tracker - Expense Sharing and Management App
        </Typography>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          {/* Expense Tracker Module */}
          <Paper
            elevation={theme.palette.mode === 'dark' ? 2 : 0}
            sx={{
              p: 4,
              borderRadius: 3,
              bgcolor: theme.palette.background.paper,
              transition: theme.transitions.create(['transform', 'box-shadow'], {
                duration: theme.transitions.duration.shorter,
              }),
              '&:hover': {
                transform: 'translateY(-4px)',
                boxShadow: theme.shadows[10],
              },
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
              <AccountBalanceWalletIcon
                sx={{ fontSize: 40, color: theme.palette.primary.main }}
              />
              <Typography variant="h4" component="h2" fontWeight="bold" sx={{ fontSize: '2rem' }}>
                Shared Tracker
              </Typography>
            </Box>
            <Typography variant="h6" paragraph color="text.secondary" sx={{ mb: 3 }}>
              Split expenses effortlessly with friends and family. Track, manage, and settle up with ease.
            </Typography>
            <Button 
              variant="contained" 
              component={RouterLink} 
              to="/ledgers"
              state={{ from: 'home' }}
              size="large"
              sx={{
                borderRadius: 2,
                py: 1.5,
                fontSize: '1.1rem',
                background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.light} 90%)`,
                '&:hover': {
                  background: `linear-gradient(45deg, ${theme.palette.primary.dark} 30%, ${theme.palette.primary.main} 90%)`,
                }
              }}
            >
              Get Started Now
            </Button>
          </Paper>

          {/* Features Section */}
          <Box sx={{ display: 'grid', gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' }, gap: 4 }}>
            {/* Blog Module */}
            <Paper
              elevation={theme.palette.mode === 'dark' ? 2 : 0}
              sx={{
                p: 4,
                borderRadius: 3,
                bgcolor: theme.palette.background.paper,
                transition: theme.transitions.create(['transform', 'box-shadow'], {
                  duration: theme.transitions.duration.shorter,
                }),
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: theme.shadows[10],
                },
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
                <BookIcon sx={{ fontSize: 40, color: theme.palette.success.main }} />
                <Typography variant="h4" component="h2" fontWeight="bold" sx={{ fontSize: '2rem' }}>
                  Blog
                </Typography>
              </Box>
              <Typography variant="h6" paragraph color="text.secondary" sx={{ mb: 3 }}>
                Discover insights about personal finance and gaming strategies.
              </Typography>
              <Button 
                variant="contained" 
                component={RouterLink} 
                to="/blog"
                color="success"
                size="large"
                sx={{
                  borderRadius: 2,
                  py: 1.5,
                }}
              >
                Explore Articles
              </Button>
            </Paper>

            {/* Russian Roulette Module */}
            <Paper
              elevation={theme.palette.mode === 'dark' ? 2 : 0}
              sx={{
                p: 4,
                borderRadius: 3,
                bgcolor: theme.palette.background.paper,
                transition: theme.transitions.create(['transform', 'box-shadow'], {
                  duration: theme.transitions.duration.shorter,
                }),
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: theme.shadows[10],
                },
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
                <CasinoIcon sx={{ fontSize: 40, color: theme.palette.warning.main }} />
                <Typography variant="h4" component="h2" fontWeight="bold" sx={{ fontSize: '2rem' }}>
                  Roulette
                </Typography>
              </Box>
              <Typography variant="h6" paragraph color="text.secondary" sx={{ mb: 3 }}>
                Experience the thrill of the classic game of chance.
              </Typography>
              <Button 
                variant="contained"
                component={RouterLink} 
                to="/roulette"
                color="warning"
                size="large"
                sx={{
                  borderRadius: 2,
                  py: 1.5,
                }}
              >
                Play Now
              </Button>
            </Paper>

            {/* Novel Reading Section */}
            <Paper
              elevation={theme.palette.mode === 'dark' ? 2 : 0}
              sx={{
                p: 4,
                borderRadius: 3,
                bgcolor: theme.palette.background.paper,
                transition: theme.transitions.create(['transform', 'box-shadow'], {
                  duration: theme.transitions.duration.shorter,
                }),
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: theme.shadows[10],
                },
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
                <MenuBookIcon sx={{ fontSize: 40, color: theme.palette.primary.main }} />
                <Typography variant="h4" component="h2" fontWeight="bold" sx={{ fontSize: '2rem' }}>
                  Novel
                </Typography>
              </Box>
              <Typography variant="h6" paragraph color="text.secondary" sx={{ mb: 3 }}>
                Enjoy reading novels with our clean and elegant reader interface.
              </Typography>
              <Button 
                variant="contained"
                component={RouterLink} 
                to="/novel"
                color="primary"
                size="large"
                sx={{
                  borderRadius: 2,
                  py: 1.5,
                }}
              >
                Read Now
              </Button>
            </Paper>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default Home;