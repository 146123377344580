import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from '@mui/material';

function AddCollaboratorModal({ isOpen, onClose, newCollaborator, setNewCollaborator, handleAddCollaborator }) {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Add New Collaborator</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          margin="normal"
          label="Collaborator Username"
          value={newCollaborator}
          onChange={(e) => setNewCollaborator(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleAddCollaborator} color="primary">Add</Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddCollaboratorModal;